import {
  Component,
  OnInit,
  TemplateRef,
  input,
  model,
  output,
} from '@angular/core';
import { TableCheckValue, TableColumns } from '../table/table.model';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from '../loader/loader.component';
import { ReactiveFormsModule } from '@angular/forms';

@Component({
    selector: 'lib-expandable-table',
    imports: [CommonModule, LoaderComponent, ReactiveFormsModule],
    templateUrl: './expandable-table.component.html',
    styleUrl: './expandable-table.component.scss'
})
export class ExpandableTableComponent implements OnInit {
  id = input.required<string>();
  dataSource = input.required<any[]>();
  columns = input.required<TableColumns[]>();
  displayedColumns: TableColumns[] = [];
  bodyTemplateRef = model.required<TemplateRef<any>[]>();
  selectable = input<boolean>(false);
  onMasterSelect = output<TableCheckValue>();
  onSelectItem = output<TableCheckValue>();
  sortBtnIcon = input<string>('');
  stickyHeader = input<boolean>(false);
  tableClass = input<string>('');
  cellStyle = input<string>('');
  rowStyle = input<string>('');
  isAllSelected = false;
  selectionItems: any[] = [];
  tableAction = input<boolean>(false);
  tableTitle = input<string>('');
  tableExport = input<boolean>(false);
  onTableExport = output();
  tableFilter = input<boolean>(false);
  onTableFilter = output();
  tableFilterSearch = input<boolean>(false);
  onTableFilterSearch = output();
  noDataImg = input<string>('');
  noDataTitle = input<string>('');
  noDataDescription = input<string>('');
  loading = input<boolean>(false);

  ngOnInit() {
    this.displayedColumns = this.columns();
  }

  sortColumn(column: TableColumns) {
    column.isSorted = !column.isSorted;
    this.dataSource().sort((a, b) => {
      const valueA = a[column.field as string];
      const valueB = b[column.field as string];
      return column.isSorted
        ? valueA > valueB
          ? 1
          : -1
        : valueA < valueB
          ? 1
          : -1;
    });
  }

  toggleAll(event: any) {
    const checked = event.target.checked;
    if (checked) {
      this.isAllSelected = true;
      this.dataSource().forEach(data => {
        if (this.selectionItems.indexOf(data) < 0) {
          this.selectionItems.push(data);
        }
      });
    } else {
      this.selectionItems = [];
      this.isAllSelected = false;
    }

    this.onMasterSelect.emit({
      checked: checked,
      value: checked ? this.dataSource() : [],
    });
  }

  toggleElement(event: any, row: any) {
    const checked = event.target.checked;
    if (checked) {
      this.selectionItems.push(row);
      this.selectionItems.length == this.dataSource().length
        ? (this.isAllSelected = true)
        : (this.isAllSelected = false);
    } else {
      const index = this.selectionItems.indexOf(row);
      this.selectionItems.splice(index, 1);
      this.isAllSelected = false;
    }
    this.onSelectItem.emit({
      checked: checked,
      value: this.selectionItems,
    });
  }

  isSelected(row: any): boolean {
    return this.selectionItems.indexOf(row) >= 0;
  }

  onTableExportClicked() {
    this.onTableExport.emit();
  }

  onTableFilterClicked() {
    this.onTableFilter.emit();
  }

  onTableFilterSearchClicked() {
    this.onTableFilterSearch.emit();
  }

  toggleChildren(row: any) {
    row.expand = !row.expand;
  }
}
