import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  formatNumberWithCommas(value: string): string {
    // Remove everything except digits and dot
    value = value.replace(/[^0-9.]/g, '');

    if (value) {
      const decimalIndex = value.indexOf('.');
      if (decimalIndex !== -1) {
        // Remove additional dots after the first decimal point
        value =
          value.slice(0, decimalIndex + 1) +
          value.slice(decimalIndex + 1).replace(/\./g, '');
      }

      const parts = value.split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

      if (parts.length > 1) {
        parts[1] = parts[1].substring(0, 3); // Limit decimal part to 3 digits
      }

      return parts.join('.');
    }
    return '';
  }

  formatStringToNumber(value: string | number): number {
    if (typeof value === 'number') {
      return value;
    }
    return parseFloat(value.replace(/,/g, ''));
  }

  addControlErrors(
    error: {
      errors: { [key: string]: string[] };
      message: string;
    },
    formGroup: FormGroup
  ) {
    const errors = error.errors as { [key: string]: string[] };
    for (const [key, messages] of Object.entries(errors)) {
      let controlName = key;
      if (key.includes('.')) {
        controlName = key.replace('.', '[') + ']';
      }

      const control = formGroup.controls[controlName];
      if (control) {
        control.setErrors({
          apiError: true,
          apiErrorMessage: messages[0],
        });
        control.markAsDirty();
        control.markAsTouched();
      }
    }
  }

  addVariableControlErrors(
    error: {
      errors: { [key: string]: string[] };
      message: string;
    },
    formGroup: FormGroup
  ) {
    const errors = error.errors as { [key: string]: string[] };
    for (const [key, messages] of Object.entries(errors)) {
      let control;
      if (key.includes('.')) {
        const keySectionsArray = key.split('.');
        const grandParentGroupForm = formGroup.controls[keySectionsArray[0]];
        const parentArray = grandParentGroupForm.get(keySectionsArray[1]);
        if (keySectionsArray[keySectionsArray.length - 1] === 'en') {
          const langParentControl = (<FormGroup>parentArray).get(
            `${keySectionsArray[keySectionsArray.length - 2]}`
          );
          control = (<FormGroup>langParentControl).get(
            `${keySectionsArray[keySectionsArray.length - 1]}`
          );
        } else {
          control = (<FormGroup>parentArray).get(keySectionsArray[2]);
        }
      } else {
        control = formGroup.controls[key];
      }

      if (control) {
        control.setErrors({
          apiError: true,
          apiErrorMessage: messages[0],
        });
        control.markAsDirty();
        control.markAsTouched();
      }
    }
  }

  checkForChanges(formGroup: FormGroup, initialFormValues: unknown): boolean {
    const currentValues = formGroup.getRawValue();
    return JSON.stringify(currentValues) !== JSON.stringify(initialFormValues);
  }

  handleDynamicEquation(equationParams: (string | FormGroup)[]): any[] {
    const equation = equationParams[0] as string;
    const currentFormControlName: string = equationParams[1] as string;
    const parentFormGroup = equationParams[2] as FormGroup;
    const newEquation = [];
    if (equation) {
      if (equation.includes(' ')) {
        const equationArray = equation.split(' ');
        equationArray.forEach((element, index) => {
          if (element.startsWith(':')) {
            newEquation[index] = this.handleEquationKey(
              element,
              currentFormControlName,
              parentFormGroup
            );
          } else if ((index + 1) % 2 == 0) {
            newEquation[index] = element;
          }
        });
      } else {
        newEquation[0] = this.handleEquationKey(
          equation,
          currentFormControlName,
          parentFormGroup
        );
      }
    }
    return newEquation;
  }

  handleEquationKey(
    equationKey: string,
    currentFormControlName: string,
    parentFormGroup: FormGroup
  ): FormControl {
    const equationControlKey = equationKey.substring(1);
    if (equationControlKey !== currentFormControlName) {
      parentFormGroup.addControl(equationControlKey, new FormControl(''));
      return parentFormGroup.controls[equationControlKey] as FormControl;
    }
    return parentFormGroup.controls[equationKey] as FormControl;
  }

  evaluateExpression(equation: string): number {
    try {
      if (!equation) {
        return 0;
      }
      return new Function(`return ${equation}`)();
    } catch (error) {
      return 0;
    }
  }
}
