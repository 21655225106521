import { Route } from '@angular/router';
import { canDeactivateGuard } from '../common/guards/can-deactivate/can-deactivate.guard';

export const adminRoutes: Route[] = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'users-database',
  },
  {
    path: 'home',
    async loadComponent() {
      const c = await import('../pages/admin-flow/home/home.component');
      return c.HomeComponent;
    },
  },
  {
    path: 'departments',
    async loadComponent() {
      const c = await import(
        '../pages/admin-flow/departments/department-list/departments.component'
      );
      return c.DepartmentsComponent;
    },
  },
  {
    path: 'departments/department-form',
    async loadComponent() {
      const c = await import(
        '../pages/admin-flow/departments/department-form/department-form.component'
      );
      return c.DepartmentFormComponent;
    },
  },
  {
    path: 'departments/:id',
    async loadComponent() {
      const c = await import(
        '../pages/admin-flow/departments/department-details/department-details.component'
      );
      return c.DepartmentDetailsComponent;
    },
  },
  {
    path: 'departments/department-form/:id',
    async loadComponent() {
      const c = await import(
        '../pages/admin-flow/departments/department-form/department-form.component'
      );
      return c.DepartmentFormComponent;
    },
  },
  {
    path: 'users-database',
    async loadComponent() {
      const c = await import(
        '../pages/admin-flow/users-database/user-list/users-list.component'
      );
      return c.UsersDatabaseListComponent;
    },
  },
  {
    path: 'users-database/user-form',
    async loadComponent() {
      const c = await import(
        '../pages/admin-flow/users-database/user-form/user-form.component'
      );
      return c.UserFormComponent;
    },
  },
  {
    path: 'users-database/:id',
    async loadComponent() {
      const c = await import(
        '../pages/admin-flow/users-database/user-details/user-details.component'
      );
      return c.UserDetailsComponent;
    },
  },
  {
    path: 'users-database/user-form/:id',
    async loadComponent() {
      const c = await import(
        '../pages/admin-flow/users-database/user-form/user-form.component'
      );
      return c.UserFormComponent;
    },
  },
  {
    path: 'groups',
    async loadComponent() {
      const c = await import(
        '../pages/admin-flow/groups/group-list/group-list.component'
      );
      return c.GroupListComponent;
    },
  },
  {
    path: 'groups/group-form',
    async loadComponent() {
      const c = await import(
        '../pages/admin-flow/groups/group-form/group-form.component'
      );
      return c.GroupFormComponent;
    },
  },
  {
    path: 'groups/group-form/:id',
    async loadComponent() {
      const c = await import(
        '../pages/admin-flow/groups/group-form/group-form.component'
      );
      return c.GroupFormComponent;
    },
  },
  {
    path: 'groups/:id',
    async loadComponent() {
      const c = await import(
        '../pages/admin-flow/groups/group-details/group-details.component'
      );
      return c.GroupDetailsComponent;
    },
  },
  {
    path: 'workflow/variables',
    canDeactivate: [canDeactivateGuard],
    async loadComponent() {
      const c = await import(
        '../pages/admin-flow/variables/variables.component'
      );
      return c.VariablesComponent;
    },
  },
  {
    path: 'project/variables',
    canDeactivate: [canDeactivateGuard],
    async loadComponent() {
      const c = await import(
        '../pages/admin-flow/variables/variables.component'
      );
      return c.VariablesComponent;
    },
  },
  {
    path: 'job-titles',
    async loadComponent() {
      const c = await import(
        '../pages/admin-flow/job-title/job-title-list/job-title-list.component'
      );
      return c.JobTitleListComponent;
    },
  },
  {
    path: 'job-titles/job-title-form',
    async loadComponent() {
      const c = await import(
        '../pages/admin-flow/job-title/job-title-form/job-title-form/job-title-form.component'
      );
      return c.JobTitleFormComponent;
    },
  },
  {
    path: 'job-titles/job-title-form/:id',
    async loadComponent() {
      const c = await import(
        '../pages/admin-flow/job-title/job-title-form/job-title-form/job-title-form.component'
      );
      return c.JobTitleFormComponent;
    },
  },
  {
    path: 'job-titles/job-title-details/:id',
    async loadComponent() {
      const c = await import(
        '../pages/admin-flow/job-title/job-title-details/job-title-details/job-title-details.component'
      );
      return c.JobTitleDetailsComponent;
    },
  },
];
