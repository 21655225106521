@if (!singleCheckBox()) {
  @if (label()) {
    <label [for]="id() + '_label_0'" class="label">
      {{ label() }}
    </label>
  }

  @for (option of options(); track option; let idx = $index) {
    <label
      class="container at-flex at-align-start at-position-relative at-cursor-pointer at-text-md at-mb-sm"
      [ngClass]="{ disabled: disabled() || option.disabled }"
      [formGroup]="formGroup()">
      <input
        type="checkbox"
        class="checkbox at-position-absolute"
        [attr.name]="inputFormControlName()"
        [value]="option.value"
        [checked]="isOptionChecked(option.value)"
        (change)="updateValue($event, option.value)"
        [id]="id() + '_label_' + idx" />

      <span
        class="checkmark at-position-relative at-bg-white at-me-md at-border at-border-neutral-300 at-radius-x-sm"></span>

      <div
        class="container__content at-cursor-pointer at-text-md at-fw-medium at-flex at-flex-column">
        @if (option && option.label) {
          <div class="option_label at-text-neutral-900">
            {{ option.label + (required() ? ' *' : '') }}
          </div>
        }
        @if (option && option.description) {
          <div class="option_description at-text-neutral-500">
            {{ option.description }}
          </div>
        }
      </div>
    </label>
  }
} @else {
  <label
    class="container at-flex at-align-center at-position-relative at-cursor-pointer at-text-md at-mb-sm"
    [ngClass]="{ disabled: disabled() }"
    [formGroup]="formGroup()">
    <input
      type="checkbox"
      class="checkbox at-position-absolute"
      [formControlName]="inputFormControlName()"
      [attr.name]="inputFormControlName()"
      [id]="id() + '_label_'" />

    <span
      class="checkmark at-position-relative at-bg-white at-me-md at-border at-border-neutral-300 at-radius-x-sm"></span>

    <div
      class="container__content at-text-md at-fw-medium at-flex at-flex-column">
      <div
        class="option_label"
        [class]="
          disabled()
            ? 'at-text-neutral-300 at-cursor-not-allowed'
            : 'at-text-neutral-900 at-cursor-pointer'
        "
        [class.label-required]="required()">
        {{ label() }}
      </div>
    </div>
  </label>
}
