<div class="tabs-container">
  <ul
    class="nav-tabs at-flex at-align-center"
    (click)="preventSwitchTabs() && discard.emit()"
    [class]="tabsClass()"
    [ngClass]="{
      'pill at-gap-md': tabPill(),
      'at-border-bottom': tabsBorder(),
    }">
    @for (tab of tabs; track $index) {
      <li
        class="tab at-flex at-align-center"
        [ngClass]="{
          active: tab.active() === true && !tabPill(),
          disable: tab.disable(),
          pill: tabPill(),
          pill_active: tab.active() === true && tabPill(),
          'at-py-sm at-px-md': tabPill(),
          'at-p-lg': !tabPill(),
        }"
        (click)="!tab.disable() ? onSelectTab(tab, $index) : null">
        @if (tab.tabDefaultIcon()) {
          <img
            [src]="tab.active() ? tab.tabActiveIcon() : tab.tabDefaultIcon()"
            class="tab-icon"
            [alt]="tab.tabTitle()" />
        }
        <span
          class="at-fw-medium"
          [ngClass]="{
            'at-text-xs': tabPill(),
            'at-text-sm': !tabPill(),
            'at-text-primary-700': tab.active(),
            'at-text-neutral-700': !tab.active(),
          }">
          {{ tab.tabTitle() }}
        </span>

        @if (tab.showTabTag()) {
          <span
            class="tab-tag at-text-xs at-fw-medium at-bg-neutral-50 at-radius-xxl">
            {{ tab.tabTag() }}
          </span>
        }
      </li>
    }
  </ul>
  <ng-content></ng-content>
</div>
