@if (label()) {
  <label
    class="at-mb-x-sm at-block at-text-neutral-900 at-fw-medium"
    [ngClass]="{
      'at-cursor-not-allowed at-text-neutral-300': disabled(),
      'at-cursor-pointer at-text-neutral-900': !disabled(),
      'label-required': required(),
    }"
    (click)="!disabled() && toggleDropdown()"
    [for]="id()">
    {{ label() }}
  </label>
}

<!-- Select Container -->
<div
  class="at-position-relative"
  libClickOutSide
  (clickOutside)="clickedOutside()">
  <label
    (click)="!disabled() && toggleDropdown()"
    class="select at-flex at-align-center at-w-100 at-border at-border-neutral-300 at-radius-sm at-drop-shadow-xs at-text-sm at-ps-md at-pe-md"
    [class]="{
      'at-cursor-not-allowed at-text-neutral-300': disabled(),
      'at-cursor-pointer': !disabled(),
      focus: isDropdownOpen,
      'at-text-neutral-400':
        !disabled() &&
        !(childControl.touched && childControl.dirty && childControl.errors),
      'has-errors at-text-destructive-900':
        childControl.touched && childControl.dirty && childControl.errors,
    }"
    [class]="inputClass()">
    <input
      type="hidden"
      [id]="id()"
      [readOnly]="readonly()"
      [formControlName]="inputFormControlName()"
      [required]="required()"
      [value]="value() ?? null"
      [placeholder]="placeholder()"
      class="hidden-input" />

    @if (selectedOption()?.label) {
      <span
        [class]="
          disabled()
            ? 'at-cursor-not-allowed at-text-neutral-300'
            : 'at-text-neutral-900'
        ">
        {{ selectedOption()?.label | truncate: 28 }}
      </span>
    } @else {
      <span
        [class]="
          disabled()
            ? 'at-cursor-not-allowed at-text-neutral-300'
            : 'at-text-neutral-400'
        ">
        {{ placeholder() | truncate: 32 }}
      </span>
    }

    <div
      class="icon at-inset-inline-end-md at-cursor-pointer"
      [attr.disabled]="disabled()">
      <svg
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M19.9201 8.94995L13.4001 15.47C12.6301 16.24 11.3701 16.24 10.6001 15.47L4.08008 8.94995"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round" />
      </svg>
    </div>
  </label>

  @if (isDropdownOpen) {
    <div
      class="dropdown-list at-inset-inline-start-0 at-position-absolute at-text-start"
      (scroll)="onScroll($event)">
      @if (hasSearch()) {
        <input
          class="dropdown-search at-position-sticky at-inset-block-start-0 at-py-md at-px-2xl at-w-100"
          type="search"
          [placeholder]="searchPlaceholder()"
          (input)="onDropdownSearch($event)" />
      }
      @if (!showNoSearchResult) {
        @if (isLoading() && page() === 1) {
          <lib-loader [size]="'sm'" />
        } @else if (filteredOptions.length || options().length) {
          @for (
            option of filteredOptions.length ? filteredOptions : options();
            track option
          ) {
            <div
              class="dropdown-item at-cursor-pointer at-flex at-align-center at-py-md at-px-2xl at-border-inline-start-3 at-border-white"
              (click)="selectOption(option, true); isDropdownOpen = false">
              @if (option) {
                @if (option[selectedKeyName()]) {
                  {{ option[selectedKeyName()] }}
                }
              }
            </div>
          }

          @if (isLoading() && page() > 1) {
            <lib-loader [size]="'sm'" />
          }
        } @else {
          <div
            class="at-py-2xl at-px-lg at-flex at-flex-column at-align-center at-justify-center at-gap-lg">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="57"
              height="56"
              viewBox="0 0 57 56"
              fill="none">
              <path
                opacity="0.4"
                d="M27.3327 48.9993C39.575 48.9993 49.4993 39.075 49.4993 26.8327C49.4993 14.5904 39.575 4.66602 27.3327 4.66602C15.0904 4.66602 5.16602 14.5904 5.16602 26.8327C5.16602 39.075 15.0904 48.9993 27.3327 48.9993Z"
                fill="#D1D5DB" />
              <path
                d="M50.2 51.3333C49.78 51.3333 49.36 51.17 49.0566 50.8666L44.7166 46.5266C44.0866 45.8966 44.0866 44.87 44.7166 44.2166C45.3466 43.5866 46.3733 43.5866 47.0266 44.2166L51.3666 48.5566C51.9966 49.1866 51.9966 50.2133 51.3666 50.8666C51.04 51.17 50.62 51.3333 50.2 51.3333Z"
                fill="#4B4EFC" />
              <path
                d="M33.1667 29.0508H21.5C20.5433 29.0508 19.75 28.2574 19.75 27.3008C19.75 26.3441 20.5433 25.5508 21.5 25.5508H33.1667C34.1233 25.5508 34.9167 26.3441 34.9167 27.3008C34.9167 28.2574 34.1233 29.0508 33.1667 29.0508Z"
                fill="#4B4EFC" />
            </svg>
            {{ noDataLabel() }}
          </div>
        }
      } @else {
        <div class="at-py-md at-px-2xl">
          {{ noSearchResultLabel() }}
        </div>
      }
    </div>
  }
</div>
