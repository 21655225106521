import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { environment } from 'apps/kuiper/src/environments/environment';
import { AppStorage } from '../../enums/app-storage';
import { ValidatorFn, AbstractControl, FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  baseUrl = environment.baseUrl;
  isLoggedIn = signal<boolean>(false);
  constructor(private http: HttpClient) {
    this.isLoggedIn.set(this.isAuthenticated());
  }

  login(form: { email: string; password: string }) {
    return this.http.post(
      `${this.baseUrl}/api/v1/application/auth/login`,
      form
    );
  }

  isAuthenticated() {
    return !!localStorage.getItem(AppStorage.token);
  }

  matchingPasswords(
    passwordKey: string,
    passwordConfirmationKey: string
  ): ValidatorFn {
    return (control: AbstractControl): any => {
      const formGroup = control as FormGroup;
      const password = formGroup.controls[passwordKey];
      const confirmPassword = formGroup.controls[passwordConfirmationKey];
      if (
        password &&
        confirmPassword &&
        password.value !== confirmPassword.value
      ) {
        return confirmPassword.setErrors({ mismatchPassword: true });
      }
      return confirmPassword.setErrors(null);
    };
  }

  createPassword(
    password: string,
    password_confirmation: string,
    invitation_id: string,
    otp: string
  ) {
    return this.http.post(`${this.baseUrl}/api/v1/auth/create-password`, {
      password,
      password_confirmation,
      invitation_id,
      otp,
    });
  }

  validateOtp(request: { invitation_id: string; otp: string }) {
    return this.http.post(`${this.baseUrl}/api/v1/auth/validate-otp`, request);
  }
}
