import { Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
    selector: 'lib-loader',
    imports: [NgClass],
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {
  @Input() size: 'lg' | 'sm' = 'lg';
}
