@if (childControl!.errors && childControl!.dirty && childControl!.touched) {
  <ul
    [ngClass]="
      messageClass()
        ? messageClass()
        : 'at-text-destructive-500 at-text-sm at-fw-normal at-mt-sm'
    ">
    @for (
      error of childControl!.errors | keyvalue;
      track $index;
      let first = $first
    ) {
      @if (first) {
        <li>
          @if (errorMessages) {
            @if (errorMessages[error.key]) {
              @if (truncateValue()) {
                {{ errorMessages[error.key] | truncate: truncateValue() }}
              } @else {
                {{ errorMessages[error.key] }}
              }
            } @else {
              {{ label() + ' is not valid' }}
            }
          }
        </li>
      }
    }
  </ul>
}
