<div class="at-flex at-justify-between at-my-sm">
  <div>
    @if (showChangeSize()) {
      <ul class="at-flex at-justify-center">
        <li
          class="at-flex at-flex-column at-align-center at-justify-center at-mx-sm">
          <span>{{ sizeOptionsLabel() }}</span>
        </li>
        @for (size of sizeOptions(); track $index) {
          <li
            class="at-flex at-flex-column at-align-center at-justify-center at-mx-sm">
            <a
              class="at-justify-center at-align-center at-text-sm at-w-100 at-radius-x-sm"
              [ngClass]="pageBtnStyle()"
              [attr.aria-current]="size === currentSize() ? 'size' : null"
              [attr.aria-label]="
                size === currentSize()
                  ? 'Current size, size ' + size
                  : 'Go to Size ' + size
              "
              [class.current]="size === currentSize()"
              tabindex="0"
              (click)="changeSize(size)">
              {{ size }}</a
            >
          </li>
        }
      </ul>
    }
  </div>

  <nav class="at-flex at-align-center" aria-label="pagination">
    <ul class="at-flex at-justify-center">
      <li
        class="at-flex at-flex-column at-align-center at-justify-center at-mx-sm">
        <button
          class="at-text-md at-radius-x-sm at-px-lg at-py-sm at-cursor-pointer at-flex at-align-center at-gap-sm"
          aria-label="Go To Previous Page"
          [attr.aria-disabled]="currentPage() === 1"
          [disabled]="currentPage() === 1"
          (click)="onPrevious()">
          @if (previousBtnIcon()) {
            <img [src]="previousBtnIcon()" alt="previous" class="" />
          }
          {{ previousBtnLable() }}
        </button>
      </li>
      @for (page of pages; track $index) {
        <li
          class="at-flex at-flex-column at-align-center at-justify-center at-mx-sm">
          <a
            class="at-justify-center at-align-center at-text-sm at-w-100 at-radius-x-sm"
            [ngClass]="pageBtnStyle()"
            [attr.aria-current]="page === currentPage() ? 'page' : null"
            [attr.aria-label]="
              page === currentPage()
                ? 'Current Page, Page ' + page
                : 'Go to Page ' + page
            "
            [class.current]="page === currentPage()"
            [class.disable]="page < 0"
            tabindex="0"
            (click)="onGoTo(page)"
            [attr.aria-disabled]="page < 0">
            {{ page > 0 ? page : '...' }}</a
          >
        </li>
      }
      <li
        class="at-flex at-flex-column at-align-center at-justify-center at-mx-sm">
        <button
          class="at-text-md at-radius-x-sm at-px-lg at-py-sm at-cursor-pointer at-flex at-align-center at-gap-sm"
          aria-label="Go To Next Page"
          [attr.aria-disabled]="currentPage() === totalPages()"
          [disabled]="currentPage() === totalPages()"
          (click)="onNext()">
          <span> {{ nextBtnLable() }} </span>
          @if (nextBtnIcon()) {
            <img [src]="nextBtnIcon()" alt="next" class="" />
          }
        </button>
      </li>
    </ul>
  </nav>
</div>
