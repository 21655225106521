import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AppStorage } from '../../enums/app-storage';
import { RoleTypes } from '../../enums/role-types';

export const adminAuthGuard: CanActivateFn = () => {
  const router = inject(Router);
  const isTokenPresent = !!localStorage.getItem(AppStorage.token);
  const role = localStorage.getItem(AppStorage.role);
  const isRoleAdmin = role
    ? JSON.parse(role as string).name === RoleTypes.SUPER_ADMIN
    : false;

  if (!isTokenPresent) {
    router.navigate(['/auth/login']);
  }

  if (isTokenPresent && !isRoleAdmin) {
    router.navigate(['/']);
  }

  return isTokenPresent && isRoleAdmin;
};
