@if (label()) {
  <label
    class="at-mb-x-sm at-text-sm"
    [ngClass]="{
      'at-text-neutral-300 at-cursor-not-allowed ': disabled(),
      'at-text-neutral-900 at-fw-medium': !disabled(),
      'label-required': required(),
    }"
    [for]="id()"
    >{{ label() }}
  </label>
}

<!-- Input Container -->
<div class="at-position-relative at-flex at-align-center">
  @if (startIcon()) {
    <div
      class="icon at-inset-inline-start-md"
      [ngClass]="{
        'opacity-30 at-cursor-not-allowed ': disabled(),
      }">
      <img [src]="startIcon()" alt="start-icon" />
    </div>
  }

  <textarea
    [id]="id()"
    [readOnly]="readonly()"
    [style.width.px]="width()"
    [style.height.px]="height()"
    [formControlName]="inputFormControlName()"
    [required]="required()"
    [attr.minlength]="minlength()"
    [attr.maxlength]="maxlength()"
    [value]="value() ?? null"
    [placeholder]="placeholder()"
    class="at-w-100 at-border at-border-neutral-300 at-radius-sm at-drop-shadow-xs at-text-sm at-text-neutral-900 at-p-md"
    [class]="inputClass()"
    [ngClass]="{
      'has-errors at-text-destructive-900':
        childControl.touched && childControl.dirty && childControl.errors,
      'at-text-neutral-300': disabled(),
    }"
    [class.at-ps-5xl]="startIcon()"
    [class.at-pe-5xl]="endIcon()"
    [rows]="rowsNumber()"
    libTrim></textarea>

  @if (endIcon()) {
    <div
      (click)="applyActionOnEndIconClicked()"
      class="icon at-inset-inline-end-md at-cursor-pointer"
      [ngClass]="{
        'opacity-30 at-cursor-not-allowed ': disabled(),
      }">
      <img [src]="endIcon()" alt="end-icon" />
    </div>
  }
</div>
