<div class="modal-container" [ngClass]="{ active: modal().isOpen }">
  <div
    [ngStyle]="{
      'width.px': modal().width,
    }"
    class="modal at-bg-white at-shadow-lg at-p-2xl">
    @if (modal().hasLikeIcon) {
      <div class="at-flex at-justify-between at-mb-6xl">
        @if (modal().hasLikeIcon) {
          <button
            type="button"
            class="like-icon at-bg-primary-50 at-rounded-circle at-flex at-justify-center at-align-center">
            <img src="assets/icons/heart/primary.svg" alt="Heart Icon" />
          </button>
        }
      </div>
    }

    @if (modal().title || modal().hasCloseIcon) {
      <div class="at-flex at-justify-between at-mb-2xl at-align-center">
        @if (modal().title) {
          <h2 class="at-text-lg at-fw-semibold at-text-neutral-900">
            {{ modal().title }}
          </h2>
        }

        @if (modal().hasCloseIcon) {
          <button
            (click)="closeModal()"
            class="at-border at-border-neutral-200 at-p-sm at-radius-lg at-cursor-pointer at-flex">
            <img src="assets/images/icons/close.svg" alt="Close Icon" />
          </button>
        }
      </div>
    }

    @if (modal().description) {
      <p class="at-normal at-text-neutral-500">
        {{ modal().description }}
      </p>
    }

    @if (modal() && modal().customizeContent) {
      <ng-container [ngTemplateOutlet]="content" />
    }
  </div>
</div>

<div
  class="overlay"
  [ngClass]="{ active: modal().isOpen }"
  (click)="!modal().canNotCloseFromOutside && closeModal()"></div>
