import { Component, input, model } from '@angular/core';

@Component({
    selector: 'lib-tab',
    imports: [],
    templateUrl: './tab.component.html',
    styleUrl: './tab.component.scss'
})
export class TabComponent {
  tabTitle = input.required<string>();
  tabDefaultIcon = input<string>('');
  tabActiveIcon = input<string>('');
  showTabTag = input<boolean>(false);
  tabTag = input<string | number | undefined>('');
  disable = input<boolean>(false);
  active = model<boolean>(false);
}
