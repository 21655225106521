@defer (when !isVertical()) {
  <div class="horizontal">
    @for (
      option of options();
      track option.position;
      let last = $last;
      let first = $first;
      let i = $index
    ) {
      <div class="options" [style.width]="100 / options().length + '%'">
        <div class="icon">
          @if (!first) {
            <div
              [class.at-bg-primary-500]="options()[i - 1].status === 'done'"
              class="line at-bg-neutral-200"></div>
          } @else {
            <div class="line"></div>
          }

          <img
            [src]="option.icon"
            [alt]="option.status"
            class="at-rounded-circle" />

          @if (!last) {
            <div
              [class.at-bg-primary-500]="options()[i + 1].status !== 'inactive'"
              class="line at-bg-neutral-200"></div>
          }
        </div>

        <div class="at-mt-sm at-px-xl at-position-relative">
          <p
            class="at-text-md at-text-neutral-900 at-flex at-align-center at-cursor-pointer"
            (click)="option.isParallel ? showSteps(option, $index) : ''">
            <span class="at-me-sm at-text-sm at-fw-medium">
              {{ option.label }}
            </span>

            @if (option.isParallel) {
              @if (isShowParallelSteps() && option.position === clickedStep()) {
                <span class="at-flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none">
                    <path
                      d="M16.0671 11.6499L13.3921 8.97487L11.7588 7.3332C11.0671 6.64154 9.94213 6.64154 9.25047 7.3332L4.9338 11.6499C4.36713 12.2165 4.77547 13.1832 5.56713 13.1832H10.2421H15.4338C16.2338 13.1832 16.6338 12.2165 16.0671 11.6499Z"
                      fill="#4B5563" />
                  </svg>
                </span>
              } @else {
                <span class="at-flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none">
                    <path
                      d="M15.4336 6.81689H10.2419H5.56695C4.76695 6.81689 4.36695 7.78356 4.93361 8.35023L9.25028 12.6669C9.94195 13.3586 11.0669 13.3586 11.7586 12.6669L13.4003 11.0252L16.0753 8.35023C16.6336 7.78356 16.2336 6.81689 15.4336 6.81689Z"
                      fill="#4B5563" />
                  </svg>
                </span>
              }
            }
          </p>

          @if (isShowParallelSteps() && option.position === clickedStep()) {
            <lib-tooltip
              [title]="'Step details'"
              [position]="'absolute'"
              [hasCloseIcon]="true"
              (close)="isShowParallelSteps.set(false)"
              [top]="'28px'"
              [left]="'-70%'"
              [toolTipTemplate]="toolTipTemplate"
              [placement]="'right'">
              <ng-template #toolTipTemplate>
                @for (item of option.parallelSteps; track $index) {
                  <div
                    class="at-pt-lg at-pb-sm at-border-bottom at-border-neutral-200">
                    <div class="at-flex at-justify-between">
                      <p
                        class="at-text-xs at-text-neutral-700 at-fw-semibold at-mb-md at-text-center">
                        {{ item.label }}
                      </p>

                      <areeb-badge
                        [label]="item.badge || ''"
                        badgeClass="at-px-sm at-border-none at-text-xs at-fw-medium {{
                          item.badge_class
                        }}"></areeb-badge>
                    </div>

                    @if (item.description) {
                      <div class="at-flex at-align-center at-gap-md">
                        <app-avatar
                          [fullName]="item.description"
                          [img]="item.icon || ''"
                          [size]="''"
                          [imgDimensions]="{
                            width: 24,
                            height: 24,
                          }"
                          [placeholderTextClass]="'at-text-xs at-fw-medium'"
                          [borderRadius]="'at-rounded-circle'" />

                        <span
                          class="at-text-xs at-fw-medium at-text-neutral-900"
                          >{{ item.description }}</span
                        >
                      </div>
                    }
                  </div>
                }
              </ng-template>
            </lib-tooltip>
          }
        </div>
      </div>
    }
  </div>
}

@defer (when isVertical()) {
  <div class="vertical at-row at-flex-column at-align-start at-ms-6xl">
    @for (option of options(); track option; let first = $first) {
      <div
        class="at-row at-align-end"
        [class.active-step]="option.status === 'active'">
        <div class="at-row at-flex-column at-align-center">
          @if (!first) {
            <div
              [class.at-bg-primary-500]="option.status !== 'inactive'"
              class="v-line at-bg-neutral-200"></div>
          }
          <img
            [src]="option.icon || 'assets/icons/' + option.status + '.svg'"
            [alt]="option.status" />
        </div>
        <div [class.info]="option.description" class="at-ms-4xl">
          <p
            [class.at-mb]="!option.description"
            class="at-text-md at-text-neutral-900">
            {{ option.label }}
          </p>
          <p class="at-text-sm at-text-neutral-500">{{ option.description }}</p>
        </div>
      </div>
    }
  </div>
}
