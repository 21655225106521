@if (label()) {
  <div class="at-flex at-justify-between at-align-center">
    <label
      class="at-mb-x-sm at-block at-fw-medium"
      [ngClass]="
        disabled()
          ? 'at-text-neutral-300'
          : 'at-text-neutral-900' + required()
            ? 'label-required'
            : ''
      ">
      {{ label() }}
    </label>
    @if (selectedOption() && !disabled()) {
      <button
        id="close"
        class="at-bg-primary-50 at-px-lg at-py-sm at-radius-sm at-cursor-pointer at-inline-block at-mb-sm"
        (click)="clearSelectedOption()">
        <svg
          class="at-block"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none">
          <path
            d="M4.16602 4.16602L15.8319 15.8319"
            stroke="#4B4EFC"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round" />
          <path
            d="M4.16615 15.8319L15.832 4.16602"
            stroke="#4B4EFC"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round" />
        </svg>
      </button>
    }
  </div>
}

<!-- Select Container -->
@if (!selectedOption()) {
  <div
    class="at-position-relative"
    libClickOutSide
    (clickOutside)="clickedOutside()">
    <label
      class="select at-flex at-align-center at-w-100 at-border at-border-neutral-300 at-radius-sm at-text-sm at-p-md"
      [ngClass]="{
        'at-cursor-not-allowed at-text-neutral-300': disabled(),
        'at-fw-medium at-cursor-pointer': !disabled(),
        focus: isDropdownOpen,
        'at-text-neutral-900':
          !disabled() &&
          !(childControl.touched && childControl.dirty && childControl.errors),
        'has-errors at-text-destructive-900':
          childControl.touched && childControl.dirty && childControl.errors,
      }"
      [class]="inputClass()">
      <input
        type="text"
        [id]="id()"
        [readOnly]="readonly() || !isSearchable()"
        [formControlName]="inputFormControlName()"
        [required]="required()"
        [value]="value() ?? null"
        [placeholder]="placeholder()"
        [style.cursor]="disabled() ? 'not-allowed' : ''"
        class="at-bg-transparent at-text-md at-w-100 at-pe-2xl"
        [ngClass]="disabled() ? 'at-text-neutral-300' : 'at-text-neutral-900'"
        (click)="!disabled() && toggleDropdown()" />

      <span
        [ngClass]="{
          'at-text-neutral-300 at-cursor-not-allowed': disabled(),
          'at-text-neutral-900': !disabled(),
        }">
      </span>

      <div
        class="icon at-position-absolute at-inset-inline-end-md at-inset-block-md at-cursor-pointer"
        [attr.disabled]="disabled()">
        @if (isSearchable()) {
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none">
            <path
              d="M9.58268 17.4993C13.9549 17.4993 17.4993 13.9549 17.4993 9.58268C17.4993 5.21043 13.9549 1.66602 9.58268 1.66602C5.21043 1.66602 1.66602 5.21043 1.66602 9.58268C1.66602 13.9549 5.21043 17.4993 9.58268 17.4993Z"
              stroke="#6B7280"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round" />
            <path
              d="M18.3327 18.3327L16.666 16.666"
              stroke="#6B7280"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        } @else {
          <svg
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M19.9201 8.94995L13.4001 15.47C12.6301 16.24 11.3701 16.24 10.6001 15.47L4.08008 8.94995"
              stroke="#6B7280"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        }
      </div>
    </label>

    @if (isDropdownOpen) {
      <div
        class="dropdown-list at-inset-inline-start-0 at-position-absolute at-text-start at-py-lg at-bg-white at-shadow-md at-radius-md"
        (scroll)="onScroll($event)">
        @if (!showNoSearchResult) {
          @for (
            option of filteredOptions.length ? filteredOptions : options();
            track option.value
          ) {
            <div
              class="dropdown-item at-cursor-pointer at-flex at-align-center at-px-md at-py-sm at-border-inline-start-3 at-border-white"
              (click)="selectOption(option, true); isDropdownOpen = false">
              @if (option) {
                <div
                  class="at-border at-border-neutral-200 at-radius-md at-p-md at-w-100">
                  <div class="at-flex at-align-center at-gap-sm">
                    @if (option.image) {
                      <img
                        [src]="option.image.url"
                        [alt]="label()"
                        class="at-radius-x-sm"
                        width="32"
                        height="32" />
                    }
                    <label class="at-text-md at-fw-semibold">
                      {{ option.label | truncate }}
                    </label>
                  </div>
                  @if (labelValueAssignList()) {
                    <div class="at-row at-gap-md at-mt-md">
                      @for (
                        item of labelValueAssignList().extraLabels;
                        track $index
                      ) {
                        <div class="option-nested-item">
                          <label
                            class="at-text-sm at-fw-medium at-text-neutral-500"
                            >{{ item.label }}</label
                          >
                          <p class="at-text-sm at-fw-semibold at-mt-x-sm">
                            {{ option | keyValue: item.key }}
                          </p>
                        </div>
                      }
                    </div>
                  }
                </div>
              }
            </div>
          }
          @if (isLoading()) {
            <lib-loader [size]="'sm'"></lib-loader>
          }
        } @else {
          <div
            class="at-py-2xl at-px-lg at-flex at-flex-column at-align-center at-justify-center at-gap-lg">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="57"
              height="56"
              viewBox="0 0 57 56"
              fill="none">
              <path
                opacity="0.4"
                d="M27.3327 48.9993C39.575 48.9993 49.4993 39.075 49.4993 26.8327C49.4993 14.5904 39.575 4.66602 27.3327 4.66602C15.0904 4.66602 5.16602 14.5904 5.16602 26.8327C5.16602 39.075 15.0904 48.9993 27.3327 48.9993Z"
                fill="#D1D5DB" />
              <path
                d="M50.2 51.3333C49.78 51.3333 49.36 51.17 49.0566 50.8666L44.7166 46.5266C44.0866 45.8966 44.0866 44.87 44.7166 44.2166C45.3466 43.5866 46.3733 43.5866 47.0266 44.2166L51.3666 48.5566C51.9966 49.1866 51.9966 50.2133 51.3666 50.8666C51.04 51.17 50.62 51.3333 50.2 51.3333Z"
                fill="#4B4EFC" />
              <path
                d="M33.1667 29.0508H21.5C20.5433 29.0508 19.75 28.2574 19.75 27.3008C19.75 26.3441 20.5433 25.5508 21.5 25.5508H33.1667C34.1233 25.5508 34.9167 26.3441 34.9167 27.3008C34.9167 28.2574 34.1233 29.0508 33.1667 29.0508Z"
                fill="#4B4EFC" />
            </svg>
            {{ noSearchResultLabel() }}
          </div>
        }
      </div>
    }
  </div>
} @else {
  <div
    class="selected-item at-border at-border-neutral-200 at-radius-md at-px-md at-py-lg at-w-100"
    [ngClass]="{ 'opacity-30': disabled() }">
    <div class="at-flex at-align-center at-gap-sm">
      @if (selectedOption()?.image) {
        <div>
          <img
            [src]="selectedOption()?.image?.url"
            [alt]="label()"
            class="at-radius-x-sm"
            width="32"
            height="32" />
        </div>
      }
      <label class="at-text-md at-fw-semibold">
        {{ selectedOption()?.label | truncate: 80 }}
      </label>
    </div>
    <div class="at-row at-gap-md at-mt-md">
      @for (item of labelValueAssignList().extraLabels; track $index) {
        <div class="option-nested-item">
          <label class="at-text-sm at-fw-medium at-text-neutral-500">{{
            item.label
          }}</label>
          <p class="at-text-sm at-fw-semibold at-mt-x-sm">
            {{ selectedOption() | keyValue: item.key }}
          </p>
        </div>
      }
    </div>
  </div>
}
