import { CanDeactivateFn } from '@angular/router';
import { Observable } from 'rxjs';

// Interface for components using this guard
export interface CanDeactivateComponent {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

export const canDeactivateGuard: CanDeactivateFn<
  CanDeactivateComponent
> = component => {
  return component.canDeactivate ? component.canDeactivate() : true;
};
