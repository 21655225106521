import { CommonModule } from '@angular/common';
import { Component, effect, inject, model, TemplateRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ModalServiceService } from './modal-service.service';
import { Modal } from './modal.interface';

@Component({
  selector: 'lib-modal',
  imports: [CommonModule],
  templateUrl: './modal.component.html',
  styleUrl: './modal.component.scss',
})
export class ModalComponent {
  modalService = inject(ModalServiceService);
  modal = model<Modal>({
    isOpen: false,
    hasCloseIcon: false,
    hasLikeIcon: false,
    title: '',
    description: '',
    customizeContent: {} as TemplateRef<unknown>,
    canNotCloseFromOutside: false,
    width: '479',
  });

  content!: TemplateRef<unknown>;

  constructor() {
    this.updateModal();
  }

  closeModal() {
    this.modal().isOpen = false;
    if (this.modal().formGroup) {
      (<FormGroup>this.modal().formGroup).reset();
    }
  }

  updateModal() {
    effect(() => {
      const modal: Modal = this.modalService.modalContent();
      this.modal.set(modal);
      this.content = modal.customizeContent as TemplateRef<unknown>;
    });
  }
}
