import { Component, input } from '@angular/core';
import { Router, RouterModule } from '@angular/router';

@Component({
    selector: 'lib-navbar',
    imports: [RouterModule],
    templateUrl: './navbar.component.html',
    styleUrl: './navbar.component.scss'
})
export class NavbarComponent {
  hasNotification = input<boolean>(false);

  constructor(public router: Router) {}
}
