import { Component, input, output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertData } from './alertData.interface';

@Component({
    selector: 'lib-alert-inline',
    imports: [CommonModule],
    templateUrl: './alert-inline.component.html',
    styleUrl: './alert-inline.component.scss'
})
export class AlertInlineComponent {
  alertData = input.required<AlertData>();
  hasCloseIcon = input<boolean>(true);
  hasIcon = input<boolean>(false);
  protected isShownAlert = true;
  onAlertclose = output<boolean>();
  hasContent = input<boolean>(false);

  protected closeAlert() {
    this.isShownAlert = false;
    this.onAlertclose.emit(false);
  }
}
