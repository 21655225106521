import { HttpClient, HttpParams } from '@angular/common/http';
import { LookupFilter, LookupOptionItem } from './lookup-field-item';
import { Injectable } from '@angular/core';
import { ApiResponse } from '../api-response.model';
import { filterInputTypesEnum } from './../../enums/filter-input-type';

@Injectable({ providedIn: 'root' })
export class LookupFieldService {
  constructor(private readonly http: HttpClient) {}

  getLookupOptions(
    url: string,
    page: number,
    filters?: LookupFilter[],
    filterValues?: string[]
  ) {
    let params = new HttpParams().append('page', page);
    if (filters?.length) {
      filters.forEach((filter, index) => {
        const filterKey = filter.key.replace(/\./g, ']['); // Replace . with ][
        const modifiedFilterKey = 'filters[' + filterKey + ']';
        const modifiedFilterValues =
          filter.value_type === filterInputTypesEnum.DYNAMIC &&
          filterValues?.length
            ? filterValues[index]
            : filter.value;
        params = params.append(modifiedFilterKey, modifiedFilterValues);
      });
    }
    return this.http.get<ApiResponse<LookupOptionItem>>(url, {
      params,
    });
  }

  getLookupOptionsRelatedData(
    url: string,
    filters?: LookupFilter[],
    filterValues?: string[]
  ) {
    let params = new HttpParams();
    if (filters?.length) {
      filters.forEach((filter, index) => {
        const filterKey = filter.key.replace(/\./g, ']['); // Replace . with ][
        const modifiedFilterKey = 'filters[' + filterKey + ']';
        const modifiedFilterValues =
          filter.value_type === filterInputTypesEnum.DYNAMIC &&
          filterValues?.length
            ? filterValues[index]
            : filter.value;
        params = params.append(modifiedFilterKey, modifiedFilterValues);
      });
    }
    return this.http.get(url, {
      params,
    });
  }
}
