@if (tableAction()) {
  <div
    class="at-my-2xl at-py-lg at-flex at-justify-between"
    [ngClass]="
      titleBottomBorder() ? 'at-border-bottom at-border-neutral-200' : ''
    ">
    <h6 class="at-text-lg at-fw-semibold at-text-neutral-900">
      @if (tableTitle()) {
        {{ tableTitle() }}
      }
    </h6>
    <div class="at-flex at-align-center">
      <div class="at-me-lg">
        <!------------------- input ------------------->
      </div>

      @if (tableExport()) {
        <div class="at-me-lg">
          <button
            (click)="onTableExportClicked()"
            Class="
          at-bg-white at-border-none at-text-primary-600 at-fw-medium at-text-sm at-cursor-pointer at-px-lg at-py-sm at-flex at-align-center
        ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none">
              <path
                d="M4.55469 8.94444L9.99913 3.5M9.99913 3.5L15.4436 8.94444M9.99913 3.5V17.5"
                stroke="#1E22FB"
                stroke-width="1.67"
                stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>

            <span class="at-ms-x-sm">Export</span>
          </button>
        </div>
      }
      @if (tableFilter()) {
        <div class="at-me-lg">
          <button
            (click)="onTableFilterClicked()"
            class="at-flex at-align-center at-px-lg at-py-sm at-radius-sm at-cursor-pointer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none">
              <path
                d="M2.5 6.3335H17.5"
                stroke="#4B4EFC"
                stroke-width="1.5"
                stroke-linecap="round" />
              <path
                d="M5 10.5H15"
                stroke="#4B4EFC"
                stroke-width="1.5"
                stroke-linecap="round" />
              <path
                d="M8.33398 14.6665H11.6673"
                stroke="#4B4EFC"
                stroke-width="1.5"
                stroke-linecap="round" />
            </svg>
          </button>
        </div>
      }
      @if (tableFilterSearch()) {
        <div>
          <button
            (click)="onTableFilterSearchClicked()"
            class="at-flex at-align-center at-px-lg at-py-sm at-radius-sm at-cursor-pointer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none">
              <path
                d="M11.9328 16.3915C11.9328 16.8998 11.5995 17.5665 11.1745 17.8248L9.99948 18.5831C8.90781 19.2581 7.39114 18.4998 7.39114 17.1498V12.6915C7.39114 12.0998 7.05781 11.3415 6.71615 10.9248L3.51612 7.55813C3.09112 7.13313 2.75781 6.38314 2.75781 5.87481V3.94147C2.75781 2.93313 3.51616 2.1748 4.44116 2.1748H15.5578C16.4828 2.1748 17.2411 2.93313 17.2411 3.85813V5.70813C17.2411 6.38313 16.8161 7.2248 16.3995 7.64147"
                stroke="#4B4EFC"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round" />
              <path
                d="M13.3913 14.2664C14.864 14.2664 16.058 13.0725 16.058 11.5998C16.058 10.127 14.864 8.93311 13.3913 8.93311C11.9185 8.93311 10.7246 10.127 10.7246 11.5998C10.7246 13.0725 11.9185 14.2664 13.3913 14.2664Z"
                stroke="#4B4EFC"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round" />
              <path
                d="M16.5579 14.7664L15.7246 13.9331"
                stroke="#4B4EFC"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </button>
        </div>
      }
    </div>
  </div>
}

<div class="custom-table-container" [ngClass]="tableClass()">
  <table class="custom-table at-w-100" [id]="id()">
    <thead class="at-bg-neutral-50" [ngClass]="{ sticky: stickyHeader() }">
      <tr class="at-border-bottom at-border-neutral-200">
        @if (selectable()) {
          <th class="at-px-lg at-py-sm">
            <label class="checkbox-container at-flex at-align-start">
              <input
                type="checkbox"
                class="checkbox"
                [checked]="isAllSelected"
                (change)="toggleAll($event)"
                [attr.name]=""
                [id]="id() + '_master_select'" />
              <span class="checkmark at-p-sm"></span>
            </label>
          </th>
        }

        @for (column of displayedColumns; track $index) {
          <ng-container>
            <th class="at-px-lg at-py-md at-text-xs at-fw-semibold">
              <div
                class="th-header at-flex at-align-center at-text-neutral-700">
                <span
                  class="at-me-x-sm at-text-xs at-fw-semibold at-text-neutral-700">
                  {{ column.header }}
                </span>

                @if (column.isRequired) {
                  <span class="at-text-destructive-500"> *</span>
                }
                @if (column.sortable) {
                  <button class="sort-btn">
                    <img
                      [src]="sortBtnIcon()"
                      (click)="sortColumn(column)"
                      alt="" />
                  </button>
                }
              </div>
            </th>
          </ng-container>
        }
      </tr>
    </thead>

    <tbody>
      @if (dataSource().length && !loading()) {
        @for (row of dataSource(); track $index; let rowIndex = $index) {
          <ng-container
            [ngTemplateOutlet]="tableRow"
            [ngTemplateOutletContext]="{ $implicit: row, i: rowIndex }">
          </ng-container>
        }
      } @else if (!dataSource().length && !loading()) {
        <tr>
          <td [attr.colspan]="columns().length" class="at-pt-8xl empty-table">
            <div
              class="at-flex at-flex-column at-justify-center at-align-center">
              <img
                class=""
                width="64px"
                height="64px"
                [src]="noDataImg()"
                alt="No Data" />
              @if (noDataTitle()) {
                <h6 class="at-h6 at-fw-semibold at-text-neutral-900 at-mt-lg">
                  {{ noDataTitle() }}
                </h6>
              }
              @if (noDataDescription()) {
                <p class="at-text-neutral-500 at-text-sm at-fw-medium at-mt-sm">
                  {{ noDataDescription() }}
                </p>
              }
            </div>
          </td>
        </tr>
      } @else {
        <tr>
          <td [attr.colspan]="columns().length" class="at-pt-8xl empty-table">
            <div
              class="at-flex at-flex-column at-justify-center at-align-center">
              <lib-loader></lib-loader>
            </div>
          </td>
        </tr>
      }
    </tbody>
  </table>
</div>

<!-- table body in case of there is data -->
<ng-template #tableRow let-row let-rowIndex="i">
  <tr class="at-border-bottom at-border-neutral-200" [class]="rowStyle()">
    @if (selectable()) {
      <td class="at-px-lg at-py-sm" [ngClass]="cellStyle()">
        <label class="checkbox-container at-flex at-align-start">
          <input
            type="checkbox"
            class="checkbox"
            [checked]="isSelected(row)"
            (change)="toggleElement($event, row)"
            [attr.name]=""
            [id]="rowIndex + '_row_select'" />
          <span class="checkmark at-p-sm"></span>
        </label>
      </td>
    }

    <!-- dynamic cells -->
    @for (
      column of displayedColumns;
      track $index;
      let isFirst = $first , isLast = $last
    ) {
      <td
        [class]="cellStyle()"
        [ngClass]="{
          'at-px-lg at-py-sm at-text-neutral-900 at-text-xs at-fw-medium':
            !column.customizeCellStyle,
        }">
        <div
          class="at-flex at-align-start at-gap-md"
          [ngClass]="$last ? lastColumnAligment() : ''">
          <ng-container
            [ngTemplateOutlet]="bodyTemplateRef()[$index]"
            [ngTemplateOutletContext]="{ $implicit: row, i: rowIndex }">
          </ng-container>
        </div>
      </td>
    }
  </tr>
</ng-template>
