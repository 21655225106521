import { Component, input, OnInit, output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ToggleOption } from '../option.interface';
import { Subscription } from 'rxjs';

@Component({
  selector: 'lib-toggle',
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './toggle.component.html',
  styleUrl: './toggle.component.scss',
})
export class ToggleComponent implements OnInit {
  subscription = new Subscription();
  id = input.required<string>();
  formGroup = input.required<FormGroup>();
  inputFormControlName = input.required<string>();
  label = input<string>();
  options = input<ToggleOption>();
  disabled = input<boolean>(false);
  valueChange = output<{ key: string; toggleValue: boolean }>();

  checkMarkWidth = input<number>(39);
  checkMarkHeight = input<number>(24);
  checkMarkAfterSize = input<number>(20);

  ngOnInit() {
    this.subscription.add(
      this.formGroup()
        .get(this.inputFormControlName())
        ?.valueChanges.subscribe(res => {
          this.valueChange.emit({
            key: this.inputFormControlName(),
            toggleValue: res,
          });
        })
    );
  }

  onDestroy() {
    this.subscription.unsubscribe();
  }
}
