<div
  class="stat_card at-w-100 at-flex at-justify-between at-align-center at-p-2xl at-radius-md at-bg-white"
  [style.width]="width()">
  <diV class="at-flex at-align-center">
    @if (icon()) {
      <span class="at-flex at-p-md at-bg-primary-50 at-radius-sm">
        <img [src]="icon()" class="stat-icon" alt="stat-icon" />
      </span>
    }
    <div
      class="stat-title-container at-flex at-flex-column at-gap-x-sm at-ms-2xl">
      @if (overline()) {
        <p class="at-text-sm at-fw-medium at-text-neutral-500">
          {{ overline() }}
        </p>
      }
      <h5 class="at-h5 at-fw-semibold at-text-neutral-900">{{ label() }}</h5>
      @if (description()) {
        <p class="at-text-sm at-fw-medium at-text-neutral-500">
          {{ description() }}
        </p>
      }
    </div>
  </diV>
  <div class="stat-tag-container">
    @if (tag()) {
      <p
        class="tag at-text-success-700 at-text-sm at-text-success-50 at-bg-success-50 at-radius-xxxl at-py-x-sm at-px-sm">
        <span style="font-size: 16px">&#8593;</span> {{ tag() }} %
      </p>
    }
  </div>
</div>
