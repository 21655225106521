import { Route } from '@angular/router';

export const authRoutes: Route[] = [
  {
    path: 'login',
    loadComponent() {
      return import('../pages/auth/login/login.component').then(
        c => c.LogInComponent
      );
    },
  },
  {
    path: 'create-password',
    loadComponent() {
      return import(
        '../pages/auth/create-password/create-password.component'
      ).then(c => c.CreatePasswordComponent);
    },
  },
];
