import {
  AfterViewInit,
  Component,
  OnInit,
  inject,
  input,
  model,
} from '@angular/core';
import { InputComponent } from '../input/input.component';
import {
  ControlContainer,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { NumberDirective } from '../../directives/number-directive/number.directive';
import { FormService } from '../../services/form/form.service';

@Component({
  selector: 'lib-money-input',
  imports: [InputComponent, ReactiveFormsModule, NumberDirective],
  templateUrl: './money-input.component.html',
  styleUrl: './money-input.component.scss',
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: () => inject(ControlContainer, { skipSelf: true }),
    },
  ],
})
export class MoneyInputComponent implements OnInit, AfterViewInit {
  private readonly parentContainer = inject(ControlContainer);
  private readonly formService = inject(FormService);

  id = input.required<string>();
  label = input<string>();
  name = input.required<string>();
  placeholder = input.required<string>();
  inputFormControlName = input.required<string>();
  viewValue = model<string>();
  required = input<boolean>(false);
  disabled = input<boolean>(false);
  formGroup = new FormGroup({
    money: new FormControl(),
  });
  equation = input<string | undefined>(undefined);

  private get parentFormGroup() {
    return this.parentContainer.control as FormGroup;
  }

  protected get childControl() {
    return this.parentFormGroup.controls[
      this.inputFormControlName()
    ] as FormControl;
  }

  ngOnInit(): void {
    if (this.required()) {
      this.formGroup.controls['money'].setValidators([Validators.required]);
    }

    if (this.disabled()) {
      this.formGroup.controls['money'].disable();
    }

    if (this.equation()) {
      const dynamicEquationParams = [
        this.equation() as string,
        this.inputFormControlName(),
        this.parentFormGroup,
      ];
      if (
        this.formService.handleDynamicEquation(dynamicEquationParams).length ===
        1
      ) {
        const equationControl = this.formService.handleDynamicEquation(
          dynamicEquationParams
        )[0];
        equationControl.statusChanges.subscribe(() => {
          this.formGroup.controls['money'].setValue(equationControl.value);
          this.onKeyPress();
        });
      }
    }
  }

  ngAfterViewInit(): void {
    if (this.childControl.value) {
      const returnedValue = this.formService.formatNumberWithCommas(
        this.childControl.value.toString()
      );
      this.viewValue.set(returnedValue);
    }
  }

  onKeyPress(event?: Event) {
    if (event) {
      const target = event.target as HTMLInputElement;
      target.value = this.formService.formatNumberWithCommas(target.value);
    }

    const value = this.formGroup.controls['money'].value;
    this.childControl.markAsTouched();
    if (value) {
      this.viewValue.set(
        this.formService.formatNumberWithCommas(value.toString())
      );
      this.childControl.setValue(Number(this.viewValue()?.replace(/,/g, '')));
      this.childControl.markAsDirty();
    } else {
      this.childControl.setValue(null);
    }
  }
}
