@if (label()) {
  <label [for]="id + '_label_0'" class="label">
    {{ label() }}
  </label>
}

@for (option of options(); track option; let idx = $index) {
  <label
    class="container at-flex at-align-center at-gap-sm at-position-relative at-cursor-pointer at-text-md"
    [class]="{ disabled: disabled() || option.disabled }"
    [formGroup]="formGroup()">
    <input
      [id]="id + '_label_' + idx"
      type="radio"
      class="radio at-position-absolute at-cursor-pointer"
      [attr.name]="inputFormControlName()"
      [value]="option.value"
      [formControlName]="inputFormControlName()" />

    <span
      class="check-mark at-rounded-circle at-flex at-justify-center at-align-center"
      [class.active]="
        option.value === formGroup().get(inputFormControlName())?.value
      ">
      @if (option.value === formGroup().get(inputFormControlName())?.value) {
        <span
          class="check-mark-inner at-rounded-circle at-bg-primary-500"></span>
      }
    </span>

    <div
      class="container__content at-cursor-pointer at-text-md at-fw-medium at-flex at-flex-column">
      @if (option && option.label) {
        <div class="option_label at-text-neutral-900 at-text-xs at-fw-medium">
          {{ option.label }}
        </div>
      }

      @if (option && option.description) {
        <div class="option_description at-text-neutral-500">
          {{ option.description }}
        </div>
      }
    </div>
  </label>
}
