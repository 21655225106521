import { CommonModule } from '@angular/common';
import { Component, effect, input, model, output } from '@angular/core';

@Component({
    selector: 'lib-paginator',
    imports: [CommonModule],
    templateUrl: './paginator.component.html',
    styleUrl: './paginator.component.scss'
})
export class PaginatorComponent {
  currentPage = input.required<number>();
  totalPages = input.required<number>();
  nextBtnLable = input<string>('Next');
  previousBtnLable = input<string>('Previous');
  nextBtnIcon = input<string>('');
  previousBtnIcon = input<string>('');
  goTo = output<number>();
  next = output<number>();
  previous = output<number>();
  pages: number[] = [];
  pageBtnStyle = input<string>();
  showChangeSize = input<boolean>(false);
  currentSize = model<number>(0);
  sizeOptions = input<number[]>([]);
  onChangeSize = output<number>();
  sizeOptionsLabel = input<string>('Rows No.');

  constructor() {
    effect(() => {
      this.pages = this.getPages(this.currentPage(), this.totalPages());
    });
  }

  onGoTo(page: number): void {
    this.goTo.emit(page);
  }

  onNext(): void {
    this.next.emit(this.currentPage() + 1);
  }

  onPrevious(): void {
    this.previous.emit(this.currentPage() - 1);
  }

  getPages(current: number, total: number): number[] {
    if (total <= 7) {
      return [...Array(total).keys()].map(x => ++x);
    }

    if (current > 5) {
      if (current >= total - 4) {
        return [1, -1, total - 4, total - 3, total - 2, total - 1, total];
      } else {
        return [1, -1, current - 1, current, current + 1, -1, total];
      }
    }

    return [1, 2, 3, 4, 5, -1, total];
  }

  changeSize(size: number) {
    this.currentSize.set(size);
    this.onChangeSize.emit(size);
  }
}
