import { TruncatePipe } from '../../pipes/truncate/truncate.pipe';
import {
  Component,
  forwardRef,
  inject,
  input,
  model,
  OnInit,
  output,
  signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ControlContainer,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { ClickOutSideDirective } from '../../directives/clickOutSide.directive';
import { LookupFieldService } from '../lookup-field/lookup-field.service';
import { ApiResponse } from '../api-response.model';
import {
  LookupOptionItem,
  LookupFilter,
  OnSelectData,
} from '../lookup-field/lookup-field-item';
import { ExpandableSelectService } from './expandable-select.service';
import { LoaderComponent } from '../loader/loader.component';

@Component({
  selector: 'lib-expandable-select',
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ClickOutSideDirective,
    TruncatePipe,
    LoaderComponent,
  ],
  templateUrl: './expandable-select.component.html',
  styleUrl: './expandable-select.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ExpandableSelectComponent),
      multi: true,
    },
  ],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: () => inject(ControlContainer, { skipSelf: true }),
    },
  ],
})
export class ExpandableSelectComponent implements OnInit {
  readonly parentContainer = inject(ControlContainer);
  readonly expandableSelectService = inject(ExpandableSelectService);
  readonly lookupFieldService = inject(LookupFieldService);

  inputFormControlName = input.required<string>();
  id = input.required<string>();
  name = input.required<string>();
  label = input<string>('Label');
  placeholder = input<string>('Placeholder');
  inputClass = input<string>('');
  disabled = model<boolean>(false);
  readonly = input<boolean>();
  value = input<string | number>();
  required = input<boolean>(false);
  options = model<LookupOptionItem[]>([]);
  selectedKeyValue = input<'value'>('value');
  selectedKeyName = input<'label'>('label');
  noSearchResultLabel = input<string>('No Data');
  apiUrl = input<string>('');
  filters = input<LookupFilter[]>([]);
  onSelectData = input<OnSelectData>();
  selectedOption = model<LookupOptionItem>({} as LookupOptionItem);
  totalPages = model<number>(1);
  selectedOptionOutput = output<LookupOptionItem>();

  page = signal<number>(1);
  pageSize = signal<number>(15);
  isLoading = signal<boolean>(false);
  isSearchable = signal<boolean>(false);

  totalItems = 0;
  isDropdownOpen = false;
  showNoSearchResult!: boolean;
  filteredOptions: LookupOptionItem[] = [];
  apiUrlParams: string[] = [];
  temporarySelectedOption: LookupOptionItem = {} as LookupOptionItem;
  onSelectDependencyField = signal<any>(null);

  private get parentFormGroup() {
    return this.parentContainer.control as FormGroup;
  }

  protected get childControl() {
    return this.parentFormGroup.controls[
      this.inputFormControlName()
    ] as FormControl;
  }

  ngOnInit(): void {
    if (this.disabled()) {
      this.childControl.disable();
    }

    if (this.value() && !this.selectedOption()) {
      this.getLookupItems();
    }

    if (this.filters()?.length) {
      this.filters().forEach((filter, index) => {
        const fieldControlName = filter.value.toString();
        this.handleControlDependencies(fieldControlName, index);
      });
    }
  }

  handleControlDependencies(fieldControlName: string, index: number) {
    const fieldControl = this.parentFormGroup.controls[fieldControlName];
    fieldControl.valueChanges.subscribe(value => {
      if (!value) {
        this.disabled.set(true);
        this.childControl.disable();
      } else if (this.isDependencyFieldsValueAvailable()) {
        this.disabled.set(false);
        this.childControl.enable();
      }
      this.selectedOption.set({} as LookupOptionItem);
      this.childControl.patchValue(null);
      this.apiUrlParams[index] = value;
      if (this.onSelectData() && this.onSelectDependencyField()) {
        this.setOnSelectDependencyField(true);
      }
    });
    if (!fieldControl.value) {
      this.disabled.set(true);
      this.childControl.disable();
    }
  }

  isDependencyFieldsValueAvailable(): boolean {
    return this.filters().every(filter => {
      const fieldControlName = filter.value.toString();
      const fieldControlValue =
        this.parentFormGroup.controls[fieldControlName].value;
      return !!fieldControlValue;
    });
  }

  toggleDropdown() {
    this.options.set([]);
    this.filteredOptions = [];
    this.temporarySelectedOption = {} as LookupOptionItem;

    if (
      !this.isDropdownOpen &&
      (this.options().length || this.filters()[0]?.value)
    ) {
      this.getLookupItems();
    }
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  getSelectedOption = (_value: number) =>
    this.options().find(({ value }) => value == _value);

  selectOption(item: LookupOptionItem) {
    if (item) {
      this.filteredOptions =
        this.expandableSelectService.resetSelectedGrandChildren(
          this.options(),
          item
        );
      this.temporarySelectedOption = item;
    }
  }

  getLookupItems() {
    this.isLoading.set(true);
    this.lookupFieldService
      .getLookupOptions(
        this.apiUrl(),
        this.page(),
        this.filters(),
        this.apiUrlParams
      )
      .subscribe({
        next: (res: ApiResponse<LookupOptionItem>) => {
          const data: LookupOptionItem[] = res.data;
          this.options.set(
            this.expandableSelectService.restructureTreeChildren(data)
          );
          if (this.value()) {
            this.selectedOption.set(
              this.getSelectedOption(this.value() as number) as LookupOptionItem
            );
          }
          this.isLoading.set(false);
        },
      });
  }

  closeDropdown() {
    if (this.isDropdownOpen) {
      this.isDropdownOpen = false;

      this.options.set([]);
      this.filteredOptions = [];
      this.temporarySelectedOption = {} as LookupOptionItem;
      this.childControl.markAsTouched();

      if (!this.childControl.value && this.childControl.hasError('required')) {
        this.childControl.markAsDirty();
      }
    }
  }

  setOptionValue(item: LookupOptionItem) {
    this.selectedOption.set(item);
    this.selectedOptionOutput.emit(item);
    this.childControl.patchValue(item[this.selectedKeyValue()]);
    this.toggleDropdown();
    this.getLookupOptionsRelatedData();
  }

  getLookupOptionsRelatedData() {
    if (this.onSelectData()?.api) {
      this.lookupFieldService
        .getLookupOptionsRelatedData(this.setOnSelectApi())
        .subscribe({
          next: (res: any) => {
            this.onSelectDependencyField.set(res.data);
            this.setOnSelectDependencyField();
          },
        });
    }
  }

  setOnSelectDependencyField(removeData?: boolean) {
    if (this.onSelectDependencyField()) {
      Object.keys(this.onSelectDependencyField()).forEach(key => {
        const controlName = `${this.inputFormControlName()}.${key}`;
        if (this.parentFormGroup.controls[controlName]) {
          this.parentFormGroup.controls[controlName].setValue(
            !removeData ? this.onSelectDependencyField()[key] : null
          );
        }
      });
    }
  }

  setOnSelectApi(): string {
    let apiUrl = (<OnSelectData>this.onSelectData()).api;
    if (apiUrl.includes(':')) {
      const apiUrlSegments = apiUrl.split('/');
      const apiUrlParamKeys = apiUrlSegments.filter(segment =>
        segment.startsWith(':')
      );
      const apiUrlParamValues = apiUrlParamKeys.map(key => {
        const keyWithoutColon = key.substring(1);
        return this.parentFormGroup.controls[keyWithoutColon].value;
      });

      apiUrlParamKeys.forEach((key, index) => {
        apiUrl = apiUrl.replace(key, apiUrlParamValues[index]);
      });
    }
    return apiUrl;
  }

  toggleChildren(option: LookupOptionItem) {
    if ('expand' in option) {
      option.expand = !option.expand;
    }
  }
}
