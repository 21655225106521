import {
  Component,
  forwardRef,
  inject,
  input,
  model,
  OnDestroy,
  OnInit,
  output,
  signal,
} from '@angular/core';
import { NgClass } from '@angular/common';
import {
  ControlContainer,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { ClickOutSideDirective } from './../../directives/clickOutSide.directive';
import { LookupFilter, LookupOptionItem } from './lookup-field-item';
import { LookupFieldService } from './lookup-field.service';
import { LoaderComponent } from '../loader/loader.component';
import { KeyValuePipe } from './../../pipes/key-value/key-value.pipe';
import { TruncatePipe } from './../../pipes/truncate/truncate.pipe';
import { debounceTime, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { ApiResponse } from '../api-response.model';

@Component({
    selector: 'lib-lookup-field',
    imports: [
        ReactiveFormsModule,
        ClickOutSideDirective,
        LoaderComponent,
        KeyValuePipe,
        NgClass,
        TruncatePipe,
    ],
    templateUrl: './lookup-field.component.html',
    styleUrl: './lookup-field.component.scss',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => LookupFieldComponent),
            multi: true,
        },
    ],
    viewProviders: [
        {
            provide: ControlContainer,
            useFactory: () => inject(ControlContainer, { skipSelf: true }),
        },
    ]
})
export class LookupFieldComponent implements OnInit, OnDestroy {
  subscribe = new Subject();
  lookupFieldService = inject(LookupFieldService);
  inputFormControlName = input.required<string>();
  id = input.required<string>();
  name = input.required<string>();
  label = input<string>('Label');
  placeholder = input<string>('Placeholder');
  inputClass = input<string>('');
  disabled = input<boolean>(false);
  readonly = input<boolean>();
  value = input<string | number>();
  required = input<boolean>(false);
  options = model<LookupOptionItem[]>([]);
  selectedKeyValue = input<'value'>('value');
  selectedKeyName = input<'label'>('label');
  noSearchResultLabel = input<string>('No Data');
  isDropdownOpen = false;
  selectedOption = model<LookupOptionItem | undefined>(undefined);
  selectedOptionOutput = output<LookupOptionItem>();
  showNoSearchResult!: boolean;
  filteredOptions: LookupOptionItem[] = [];
  apiUrl = input<string>('');
  page = signal<number>(1);
  pageSize = signal<number>(15);
  totalPages = model<number>(1);
  totalItems = 0;
  isLoading = signal<boolean>(false);
  labelValueAssignList = model.required<{
    label: string;
    value: string;
    icon: string;
    extraLabels: [
      {
        label: string;
        key: string;
      },
      {
        label: string;
        key: string;
      },
    ];
  }>();
  filters = input<LookupFilter[]>([]);
  isSearchable = signal<boolean>(false);

  private readonly parentContainer = inject(ControlContainer);

  private get parentFormGroup() {
    return this.parentContainer.control as FormGroup;
  }

  protected get childControl() {
    return this.parentFormGroup.controls[
      this.inputFormControlName()
    ] as FormControl;
  }

  ngOnInit(): void {
    if (this.disabled()) {
      this.childControl.disable();
    }

    if (this.value() && !this.selectedOption()) {
      this.getLookupItems();
    }

    // check if is the search function is in filters
    const search = this.filters().filter(f => f.key === 'search').length;
    if (search > 0 && this.filters().length) {
      this.isSearchable.set(true);
      this.lookupSearch();
    } else {
      this.handleLookupFilters();
    }
  }

  ngOnDestroy(): void {
    this.subscribe.next(1);
    this.subscribe.complete();
  }

  toggleDropdown() {
    this.filteredOptions = [];
    this.childControl.markAsTouched();
    if (
      !this.isDropdownOpen &&
      !this.options().length &&
      !this.filters()[0].value
    ) {
      this.getLookupItems();
    } else if (this.filters()[0].value && this.filters()[0].value.length >= 3) {
      this.getFilteredLookupItems();
    }
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  getLookupItems() {
    this.isLoading.set(true);
    this.lookupFieldService
      .getLookupOptions(this.apiUrl(), this.page())
      .subscribe({
        next: (response: ApiResponse<LookupOptionItem>) => {
          this.options().push(...response['data']);
          this.totalPages.set(response['meta']['last_page']);
          if (this.value()) {
            this.selectedOption.set(
              this.getSelectedOption(this.value() as number)
            );
          }
          this.isLoading.set(false);
        },
      });
  }

  onScroll(event: Event) {
    const { scrollTop, scrollHeight, clientHeight } =
      event.target as unknown as {
        scrollTop: number;
        scrollHeight: number;
        clientHeight: number;
      };
    if (
      scrollTop + clientHeight >= scrollHeight - 200 &&
      !this.isLoading() &&
      this.page() < this.totalPages()
    ) {
      this.page.set(this.page() + 1);
      if (this.filters()[0].value && this.filters()[0].value.length >= 3) {
        this.getFilteredLookupItems();
      } else {
        this.getLookupItems();
      }
    }
  }

  getSelectedOption = (_value: number) =>
    this.options().find(({ value }) => value == _value);

  selectOption(item: LookupOptionItem, updateFormControlValue?: boolean) {
    if (item) {
      item.list = [];
      this.selectedOption.set(item);
      this.selectedOptionOutput.emit(item);

      if (updateFormControlValue) {
        this.childControl.patchValue(item[this.selectedKeyValue()]);
      }
      this.filteredOptions = [];
    }
    this.selectedOption()?.list?.push(
      ...this.labelValueAssignList().extraLabels
    );
  }

  clearSelectedOption() {
    this.selectedOption.set(undefined);
    this.childControl.reset();
  }

  onDropdownSearch(event: Event) {
    if (event.target) {
      const searchValue = (<HTMLInputElement>event.target).value;
      if (this.options()) {
        if (searchValue) {
          this.filteredOptions = this.options().filter(
            (item: LookupOptionItem) => {
              return item?.label
                ? item?.label
                    .toLocaleLowerCase()
                    .includes(searchValue.toLocaleLowerCase())
                : '';
            }
          );
          if (!this.filteredOptions.length) {
            this.showNoSearchResult = true;
          } else {
            this.showNoSearchResult = false;
          }
        } else {
          this.filteredOptions = [];
          this.showNoSearchResult = false;
        }
      } else {
        this.showNoSearchResult = true;
      }
    }
  }

  getFilteredLookupItems() {
    this.isLoading.set(true);
    this.lookupFieldService
      .getLookupOptions(this.apiUrl(), this.page(), this.filters())
      .subscribe({
        next: (res: ApiResponse<LookupOptionItem>) => {
          this.filteredOptions.push(...res['data']);
          if (res.meta) {
            this.totalPages.set(res['meta']['last_page']);
          }
          this.isLoading.set(false);
        },
      });
  }

  clickedOutside() {
    this.isDropdownOpen = false;
    if (
      this.childControl.touched &&
      !this.childControl.value &&
      this.childControl.hasError('required')
    ) {
      this.childControl.markAsDirty();
    }
  }

  lookupSearch() {
    this.childControl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => this.isLoading.set(true)),
        switchMap((value: string) => {
          this.filters()[0].value = value;
          if (!this.selectedOption()) {
            if (value && value.length >= 3) {
              this.page.set(1);
              this.options.set([]);
              return this.lookupFieldService.getLookupOptions(
                this.apiUrl(),
                this.page(),
                this.filters()
              );
            } else {
              this.filteredOptions = [];
              this.showNoSearchResult = false;
              this.isLoading.set(false);
              if (!this.options().length) {
                this.getLookupItems();
              }
              return [];
            }
          }
          return [];
        }),
        tap(() => this.isLoading.set(false)),
        takeUntil(this.subscribe.asObservable())
      )
      .subscribe({
        next: (res: ApiResponse<LookupOptionItem>) => {
          this.filteredOptions = res['data'];
          this.totalPages.set(res['meta']['last_page']);
          if (!this.filteredOptions.length) {
            this.showNoSearchResult = true;
          } else {
            this.showNoSearchResult = false;
          }
        },
      });
  }

  handleLookupFilters() {
    this.filters().forEach(f => {
      f.value = f.value.replace('{', '').replace('}', '');
    });
  }
}
