import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AppStorage } from '../enums/app-storage';
import { RoleTypes } from '../enums/role-types';

export const authGuard: CanActivateFn = () => {
  const router = inject(Router);
  const isTokenPresent = !!localStorage.getItem(AppStorage.token);
  const role = localStorage.getItem(AppStorage.role);
  const isRoleEmployee = role
    ? JSON.parse(role as string)?.name === RoleTypes?.EMPLOYEE
    : false;

  if (!isTokenPresent) {
    router.navigate(['/auth/login']);
  }

  return isTokenPresent;
};
