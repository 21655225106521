import { TruncatePipe } from '../../pipes/truncate/truncate.pipe';
import {
  AfterViewInit,
  Component,
  forwardRef,
  inject,
  input,
  model,
  OnChanges,
  OnDestroy,
  OnInit,
  output,
  signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ControlContainer,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { ClickOutSideDirective } from '../../directives/clickOutSide.directive';
import { LookupFieldService } from '../lookup-field/lookup-field.service';
import {
  LookupFilter,
  LookupOptionItem,
} from '../lookup-field/lookup-field-item';
import { ApiResponse } from '../api-response.model';
import { LoaderComponent } from '../loader/loader.component';
import { filterInputTypesEnum } from '../../enums/filter-input-type';

@Component({
  selector: 'lib-paginated-select',
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ClickOutSideDirective,
    TruncatePipe,
    LoaderComponent,
  ],
  templateUrl: './paginated-select.component.html',
  styleUrl: './paginated-select.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PaginatedSelectComponent),
      multi: true,
    },
  ],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: () => inject(ControlContainer, { skipSelf: true }),
    },
  ],
})
export class PaginatedSelectComponent
  implements OnInit, AfterViewInit, OnDestroy, OnChanges
{
  inputFormControlName = input.required<string>();
  id = input.required<string>();
  name = input.required<string>();

  label = input<string>();
  placeholder = input<string>('');
  inputClass = input<string>('');

  disabled = model<boolean>(false);
  readonly = input<boolean>();
  value = input<string | number>();
  required = input<boolean>(false);

  options = signal<LookupOptionItem[]>([]);
  selectedKeyValue = input<'value'>('value');
  selectedKeyName = input<'label'>('label');

  hasSearch = input<boolean>(); // to add search option
  searchPlaceholder = input<string>('Search ...'); //to be able to translate or edit it
  noSearchResultLabel = input<string>('No search result'); //to be able to translate or edit it
  noDataLabel = input<string>('No data'); //to be able to translate or edit it

  changeOption = output<LookupOptionItem>();

  isLoading = signal<boolean>(false);
  apiUrl = input<string>('');
  filters = input<LookupFilter[]>([]);
  page = signal<number>(1);
  pageSize = signal<number>(15);
  totalPages = model<number>(1);

  isDropdownOpen = false;
  selectedOption = model<LookupOptionItem | undefined>();
  focused?: boolean;
  notEmpty?: boolean;
  searchValue!: string;
  showNoSearchResult!: boolean;
  filteredOptions: LookupOptionItem[] = [];
  destroy$ = new Subject<void>();
  apiUrlParams: string[] = [];

  readonly parentContainer = inject(ControlContainer);
  readonly lookupFieldService = inject(LookupFieldService);

  private get parentFormGroup() {
    return this.parentContainer.control as FormGroup;
  }

  protected get childControl() {
    return this.parentFormGroup.controls[
      this.inputFormControlName()
    ] as FormControl;
  }

  ngOnChanges() {
    if (!this.childControl?.value) {
      this.selectedOption.set(<LookupOptionItem>{});
    }
  }

  ngOnInit(): void {
    if (this.disabled()) {
      this.childControl.disable();
    }

    if (this.value() && !this.selectedOption()) {
      this.getLookupItems();
    }

    if (this.filters()?.length) {
      this.filters().forEach((filter, index) => {
        if (filter.value_type === filterInputTypesEnum.DYNAMIC) {
          const fieldControlName = filter.value.toString();
          this.handleControlDependencies(fieldControlName, index);
        }
      });
    }
  }

  handleControlDependencies(fieldControlName: string, index: number) {
    const fieldControl = this.parentFormGroup?.controls[fieldControlName];
    fieldControl?.valueChanges?.subscribe(value => {
      if (!value) {
        this.disabled.set(true);
        this.childControl.disable();
      } else if (this.isDependencyFieldsValueAvailable()) {
        this.disabled.set(false);
        this.childControl.enable();
      }
      this.selectedOption.set({} as LookupOptionItem);
      this.childControl.patchValue(null);
      this.apiUrlParams[index] = value;
    });
    if (!fieldControl?.value) {
      this.disabled.set(true);
      this.childControl?.disable();
    }
  }

  isDependencyFieldsValueAvailable(): boolean {
    return this.filters().every(filter => {
      if (filter.value_type === filterInputTypesEnum.DYNAMIC) {
        const fieldControlName = filter.value.toString();
        const fieldControl =
          this.parentFormGroup?.controls[fieldControlName]?.value;
        return !!fieldControl;
      }
      return true;
    });
  }

  ngAfterViewInit(): void {
    if (this.childControl) {
      setTimeout(() => {
        this.writeValue(this.childControl.value);
      }, 50);
      this.childControl.valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe(value => {
          this.writeValue(value, false);
        });
    }
  }

  getLookupItems() {
    this.isLoading.set(true);
    this.lookupFieldService
      .getLookupOptions(
        this.apiUrl(),
        this.page(),
        this.filters(),
        this.apiUrlParams
      )
      .subscribe({
        next: (response: ApiResponse<LookupOptionItem>) => {
          this.options().push(...response['data']);
          if (response.meta?.last_page) {
            this.totalPages.set(response.meta.last_page);
          }
          if (this.value()) {
            this.selectedOption.set(
              this.getSelectedOption(this.value() as number)
            );
          }
          this.isLoading.set(false);
        },
      });
  }

  onScroll(event: Event) {
    const { scrollTop, scrollHeight, clientHeight } =
      event.target as unknown as {
        scrollTop: number;
        scrollHeight: number;
        clientHeight: number;
      };
    if (
      scrollTop + clientHeight >= scrollHeight - 200 &&
      !this.isLoading() &&
      this.page() < this.totalPages()
    ) {
      this.page.set(this.page() + 1);
      this.getLookupItems();
    }
  }

  getSelectedOption = (_value: number) =>
    this.options().find(({ value }) => +value == _value);

  toggleDropdown() {
    this.filteredOptions = [];
    this.childControl.markAsTouched();
    this.isDropdownOpen = !this.isDropdownOpen;
    if (this.isDropdownOpen || this.options().length) {
      this.options.set([]);
      this.page.set(1);
      this.getLookupItems();
    }
  }

  writeValue(value: string | number, updateFormControlValue?: boolean) {
    if (value && this.options()) {
      const match = this.options().find(
        (item: LookupOptionItem) => item[this.selectedKeyValue()] === value
      );
      if (match) {
        this.selectOption(match, updateFormControlValue);
      }
    } else {
      this.selectedOption.set(<LookupOptionItem>{});
    }
  }

  selectOption(item: LookupOptionItem, updateFormControlValue?: boolean) {
    if (
      this.selectedOption()?.[this.selectedKeyValue()] !==
      item[this.selectedKeyValue()]
    ) {
      this.selectedOption.set(item);
      if (updateFormControlValue) {
        this.childControl.patchValue(item[this.selectedKeyValue()]);
      }
      this.filteredOptions = [];
      this.changeOption.emit(item);
      this.notEmpty = true;
    }
  }

  onDropdownSearch(event: Event) {
    if (event.target) {
      const searchValue = (<HTMLInputElement>event.target).value;
      if (this.options()) {
        if (searchValue) {
          this.filteredOptions = this.options().filter(
            (item: LookupOptionItem) => {
              return item?.label
                ? item?.label
                    .toLocaleLowerCase()
                    .includes(searchValue.toLocaleLowerCase())
                : '';
            }
          );
          if (!this.filteredOptions.length) {
            this.showNoSearchResult = true;
          } else {
            this.showNoSearchResult = false;
          }
        } else {
          this.filteredOptions = [];
        }
      } else {
        this.showNoSearchResult = true;
      }
    }
  }

  clickedOutside() {
    this.isDropdownOpen = false;
    if (
      this.childControl.touched &&
      !this.childControl.value &&
      this.childControl.hasError('required')
    ) {
      this.childControl.markAsDirty();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
