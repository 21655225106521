<div class="sidenav-container at-bg-white at-flex at-radius-lg">
  <div
    class="sidenav at-flex at-flex-column at-justify-between at-p-2xl"
    [class.collapsed]="isCollapsed">
    <ul class="menu">
      <div
        class="at-h-100 at-flex at-flex-column at-align-start at-justify-between">
        <div class="at-w-100">
          @for (item of menuItems(); track $index; let last = $last) {
            <li [class.expanded]="item.expanded">
              <div
                [ngClass]="!last ? 'at-mb-lg' : ''"
                class="menu-item at-flex at-align-center at-justify-between at-py-sm at-px-md at-radius-sm"
                (click)="toggleSubMenu(item)"
                [routerLink]="item.path ? [item.path] : null"
                routerLinkActive="active-link"
                [class.active-link]="item.hasActiveChild"
                (mouseenter)="mouseenter($index)"
                (mouseleave)="mouseleave($index)">
                <div class="at-flex">
                  <div class="at-pe-sm">
                    <img
                      [src]="
                        (item.active &&
                          item.path &&
                          router.url.includes(item.path)) ||
                        item.hasActiveChild
                          ? item.activeIcon
                          : item.defaultIcon
                      "
                      [alt]="item.label" />
                  </div>
                  @if (!isCollapsed) {
                    <span
                      routerLinkActive="at-text-primary-700"
                      class="at-text-xs at-fw-medium at-text-neutral-700">
                      {{ item.label }}
                    </span>
                  }
                </div>

                @if (item.children?.length && !isCollapsed) {
                  @if (!item.expanded) {
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <g id="vuesax/linear/arrow-down">
                        <g id="arrow-down">
                          <path
                            id="Vector"
                            d="M16.6 7.45831L11.1667 12.8916C10.525 13.5333 9.47503 13.5333 8.83336 12.8916L3.40002 7.45831"
                            stroke="#6B7280"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round" />
                        </g>
                      </g>
                    </svg>
                  } @else {
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M16.6004 12.5418L11.1671 7.10845C10.5254 6.46678 9.47539 6.46678 8.83372 7.10845L3.40039 12.5418"
                        stroke="#6B7280"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                  }
                }
              </div>

              @if (item.children?.length && item.expanded) {
                <ul class="submenu">
                  @for (subItem of item.children; track $index) {
                    <li
                      class="submenu-item at-flex at-align-center at-justify-between at-py-sm at-px-4xl at-my-lg at-mx-sm at-radius-sm at-text-xs at-fw-medium at-text-neutral-500"
                      [routerLink]="[subItem.path]"
                      routerLinkActive="active-link ">
                      {{ subItem.label }}
                    </li>
                  }
                </ul>
              }
            </li>
          }
        </div>
      </div>
    </ul>

    <ul class="menu">
      <button
        class="toggle-btn at-mx-md at-my-sm at-pb-2xl"
        (click)="toggleSidenav()">
        @if (!isCollapsed) {
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="transparent">
              <path
                d="M6.37996 12.5467C6.25329 12.5467 6.12663 12.5 6.02663 12.4L1.97996 8.35337C1.78663 8.16003 1.78663 7.84003 1.97996 7.6467L6.02663 3.60003C6.21996 3.4067 6.53996 3.4067 6.73329 3.60003C6.92663 3.79337 6.92663 4.11337 6.73329 4.3067L3.03996 8.00003L6.73329 11.6934C6.92663 11.8867 6.92663 12.2067 6.73329 12.4C6.63996 12.5 6.50663 12.5467 6.37996 12.5467Z"
                fill="#6B7280" />
              <path
                d="M13.6668 8.50003L2.44678 8.50003C2.17344 8.50003 1.94678 8.27336 1.94678 8.00003C1.94678 7.7267 2.17344 7.50003 2.44678 7.50003L13.6668 7.50003C13.9401 7.50003 14.1668 7.7267 14.1668 8.00003C14.1668 8.27337 13.9401 8.50003 13.6668 8.50003Z"
                fill="#6B7280" />
            </svg>
          </div>
        } @else {
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none">
              <path
                d="M9.62004 3.45332C9.74671 3.45332 9.87337 3.49998 9.97337 3.59998L14.02 7.64665C14.2134 7.83998 14.2134 8.15998 14.02 8.35332L9.97337 12.4C9.78004 12.5933 9.46004 12.5933 9.26671 12.4C9.07337 12.2067 9.07337 11.8867 9.26671 11.6933L12.96 7.99998L9.26671 4.30665C9.07337 4.11332 9.07337 3.79332 9.26671 3.59998C9.36004 3.49998 9.49337 3.45332 9.62004 3.45332Z"
                fill="#6B7280" />
              <path
                d="M2.33334 7.5L13.5533 7.5C13.8267 7.5 14.0533 7.72667 14.0533 8C14.0533 8.27333 13.8267 8.5 13.5533 8.5L2.33334 8.5C2.06001 8.5 1.83334 8.27333 1.83334 8C1.83334 7.72667 2.06001 7.5 2.33334 7.5Z"
                fill="#6B7280" />
            </svg>
          </div>
        }
      </button>

      <div class="at-border-top at-border-neutral-200">
        @for (item of footerItems(); track $index) {
          <li [class.expanded]="item.expanded">
            <div
              class="menu-item at-flex at-align-center at-justify-between at-py-sm at-px-md at-my-lg at-radius-sm at-bg-white at-text-neutral-700"
              (click)="toggleSubMenu(item)"
              [routerLink]="item.path ? [item.path] : null"
              [ngClass]="{ logout: item.label === 'Sign out' }"
              (mouseenter)="mouseenter($index, item.label)"
              (mouseleave)="mouseleave($index, item.label)"
              routerLinkActive="active-link">
              <div class="at-flex at-pt-sm">
                <div class="at-pe-sm">
                  <img [src]="item.defaultIcon" [alt]="item.label" />
                </div>
                @if (!isCollapsed) {
                  <span class="at-text-xs at-fw-medium">
                    {{ item.label }}
                  </span>
                }
              </div>
            </div>
          </li>
        }
      </div>
    </ul>
  </div>
</div>
