<label
  class="container at-flex at-align-start at-position-relative at-text-md"
  [class.at-mb-sm]="options()?.label"
  [ngClass]="{
    'disabled at-cursor-not-allowed': options()?.disabled || disabled(),
    'at-cursor-pointer': !options()?.disabled && !disabled(),
  }"
  [formGroup]="formGroup()">
  <input
    type="checkbox"
    class="checkbox at-position-absolute"
    [ngClass]="disabled() ? 'at-cursor-not-allowed' : 'at-cursor-pointer'"
    [attr.name]="inputFormControlName()"
    [value]="options()?.value"
    [disabled]="disabled()"
    [formControlName]="inputFormControlName()" />

  <span
    [ngStyle]="{
      'width.px': checkMarkWidth(),
      'height.px': checkMarkHeight(),
    }"
    [class.at-me-sm]="options()?.label"
    [class.at-mt-x-sm]="options()?.label"
    class="check-mark at-rounded-circle">
    <span
      [ngStyle]="{
        'width.px': checkMarkAfterSize(),
        'height.px': checkMarkAfterSize(),
      }"
      class="check-mark-icon at-rounded-circle at-bg-primary-500"></span>
  </span>

  <div
    class="container__content at-cursor-pointer at-text-md at-fw-medium at-flex at-flex-column">
    @if (options()?.label) {
      <div class="option_label at-text-neutral-900">{{ options()?.label }}</div>
    }
    @if (options()?.description) {
      <div class="option_description at-text-neutral-500">
        {{ options()?.description }}
      </div>
    }
  </div>
</label>
