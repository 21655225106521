import { Injectable } from '@angular/core';
import { LookupOptionItem } from '../lookup-field/lookup-field-item';

@Injectable({ providedIn: 'root' })
export class ExpandableSelectService {
  // Recursively processes and modifies a list of tree nodes.
  restructureTreeChildren(treeData: LookupOptionItem[]): LookupOptionItem[] {
    const processNodes = (nodes: LookupOptionItem[], isTopLevel = true) => {
      if (nodes?.length) {
        nodes.forEach((node: LookupOptionItem) => {
          // Add properties to the current node
          node.expand = false;
          node.isTopLevel = isTopLevel;

          // Recursively process child nodes if they exist
          if (node.children?.length) {
            processNodes(node.children, false);
          } else {
            node.isSelected = false;
          }
        });
      }
    };

    processNodes(treeData);
    return treeData;
  }

  // Recursively processes and modifies a list of tree nodes.
  resetSelectedGrandChildren(
    treeData: LookupOptionItem[],
    item: LookupOptionItem
  ): LookupOptionItem[] {
    const processNodes = (nodes: LookupOptionItem[]) => {
      if (nodes?.length) {
        nodes.forEach((node: LookupOptionItem) => {
          // Recursively process child nodes if they exist
          if (node.children?.length) {
            processNodes(node.children);
          } else {
            node.isSelected = node.value === item.value ? true : false;
          }
        });
      }
    };

    processNodes(treeData);
    return treeData;
  }
}
