import { Component, inject, input, OnInit, output } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ControlContainer,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TrimDirective } from '../../directives/trim/trim.directive';

@Component({
    selector: 'lib-input',
    imports: [CommonModule, ReactiveFormsModule, TrimDirective],
    templateUrl: './input.component.html',
    styleUrl: './input.component.scss',
    viewProviders: [
        {
            provide: ControlContainer,
            useFactory: () => inject(ControlContainer, { skipSelf: true }),
        },
    ]
})
export class InputComponent implements OnInit {
  inputFormControlName = input.required<string>();
  id = input.required<string>();
  type = input.required<string>();
  name = input.required<string>();

  label = input<string>();
  placeholder = input<string>('');
  inputClass = input<string>('');

  startIcon = input<string>();
  endIcon = input<string>();

  min = input<number | string>();
  max = input<number | string>();
  minlength = input<number>();
  maxlength = input<number>();

  disabled = input<boolean>(false);
  readonly = input<boolean>();
  value = input<string | number>();
  required = input<boolean>(false);

  endIconCLick = output<void>();
  typing = output<string>();

  private readonly parentContainer = inject(ControlContainer);

  private get parentFormGroup() {
    return this.parentContainer.control as FormGroup;
  }

  protected get childControl() {
    return this.parentFormGroup?.controls[
      this.inputFormControlName()
    ] as FormControl;
  }

  ngOnInit(): void {
    if (this.disabled()) {
      this.childControl.disable();
    }
    if (this.required()) {
      this.childControl.addValidators(Validators.required);
    }
  }

  applyActionOnEndIconClicked() {
    this.endIconCLick.emit();
  }
}
