import { Route } from '@angular/router';
import { employeeRoutes } from './layout/employee.routes';
import { authRoutes } from './layout/auth.routes';
import { authGuard } from './common/guards/auth.guard';
import { adminRoutes } from './layout/admin.routes';
import { adminAuthGuard } from './common/guards/admin-auth/admin-auth.guard';

export const appRoutes: Route[] = [
  {
    path: '',
    loadComponent: () =>
      import('./layout/employee/employee.component').then(
        c => c.EmployeeComponent
      ),
    children: [...employeeRoutes],
    canActivate: [authGuard],
  },
  {
    path: 'admin',
    loadComponent: () =>
      import('./layout/admin/admin.component').then(c => c.AdminComponent),
    children: [...adminRoutes],
    canActivate: [adminAuthGuard],
  },
  {
    path: 'auth',
    loadComponent: () =>
      import('./layout/auth/auth.component').then(c => c.AuthComponent),
    children: [...authRoutes],
  },
];
