import { CommonModule } from '@angular/common';
import { Component, inject, input, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ToggleOption } from '../option.interface';
import { FormService } from '../../services/form/form.service';
import { combineLatest, from, map } from 'rxjs';

@Component({
  selector: 'lib-checkbox',
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent implements OnInit {
  private readonly formService = inject(FormService);

  id = input.required<string>();
  formGroup = input.required<FormGroup>();
  inputFormControlName = input.required<string>();
  label = input<string>();
  disabled = input<boolean>(false);
  options = input<ToggleOption[]>([]);
  required = input<boolean>(false);
  values: string[] = [];
  singleCheckBox = input.required<boolean>();
  equation = input<string | undefined>(undefined);

  ngOnInit(): void {
    if (this.equation()) {
      const dynamicEquationParams = [
        this.equation() as string,
        this.inputFormControlName(),
        this.formGroup(),
      ];
      if (
        this.formService.handleDynamicEquation(dynamicEquationParams).length ===
        1
      ) {
        const equationControl = this.formService.handleDynamicEquation(
          dynamicEquationParams
        )[0];
        equationControl.statusChanges.subscribe(() => {
          this.formGroup().controls[this.inputFormControlName()].setValue(
            equationControl.value
          );
        });
      } else if (
        this.formService.handleDynamicEquation(dynamicEquationParams).length > 1
      ) {
        const dynamicEquationArray = this.formService.handleDynamicEquation(
          dynamicEquationParams
        );

        // Convert FormControl to an observable of its value
        // Convert static strings to observables
        const observables = dynamicEquationArray.map(element => {
          if (element instanceof FormControl) {
            return element.statusChanges.pipe(map(() => element.value));
          }
          return from([element]);
        });

        // Combine the observables into a single observable
        combineLatest(observables).subscribe(equationParts => {
          const equation = equationParts.join(' '); // Convert the array to a string

          this.formGroup().controls[this.inputFormControlName()].setValue(
            !!this.formService.evaluateExpression(equation)
          );
        });
      }
    }
  }

  updateValue(event: Event, value: string | boolean | number) {
    const target = event.target as HTMLInputElement;
    if (target.checked) {
      this.values.push(value as string);
    } else {
      this.values = this.values.filter(v => v != value);
    }
    this.formGroup().controls[this.inputFormControlName()].setValue(
      this.values
    );
  }

  isOptionChecked(value: string | boolean | number): boolean {
    return this.values?.includes(value as string);
  }
}
