@if (label()) {
  <label
    class="at-mb-x-sm at-block at-text-neutral-900 at-fw-medium"
    [ngClass]="{
      'at-cursor-not-allowed at-text-neutral-300': disabled(),
      ' at-cursor-pointer at-text-neutral-900': !disabled(),
      'label-required': required(),
    }"
    (click)="!disabled() && toggleDropdown()"
    [for]="id()">
    {{ label() }}
  </label>
}

<!-- Select Container -->
<div
  class="at-position-relative"
  libClickOutSide
  (clickOutside)="closeDropdown()">
  <label
    (click)="!disabled() && toggleDropdown()"
    class="select at-flex at-align-center at-w-100 at-border at-border-neutral-300 at-radius-sm at-drop-shadow-xs at-text-sm at-ps-md at-pe-md"
    [ngClass]="{
      'at-cursor-not-allowed at-text-neutral-300': disabled(),
      'at-cursor-pointer': !disabled(),
      focus: isDropdownOpen,
      'at-text-neutral-400':
        !disabled() &&
        !(childControl.touched && childControl.dirty && childControl.errors),
      'has-errors at-text-destructive-900':
        childControl.touched && childControl.dirty && childControl.errors,
    }"
    [class]="inputClass()">
    <input
      type="hidden"
      [id]="id()"
      [readOnly]="readonly()"
      [formControlName]="inputFormControlName()"
      [required]="required()"
      [value]="value() ?? null"
      [placeholder]="placeholder()"
      class="hidden-input" />

    @if (selectedOption() && selectedOption().label) {
      <span
        [ngClass]="{
          'at-text-neutral-900': selectedOption().label && !disabled(),
          'at-cursor-not-allowed  at-text-neutral-300': disabled(),
        }">
        {{ selectedOption().label | truncate: 32 }}
      </span>
    } @else {
      <span
        class="at-text-neutral-300"
        [ngClass]="{
          'at-cursor-not-allowed': disabled(),
        }">
        {{ placeholder() | truncate: 32 }}
      </span>
    }

    <div
      class="icon at-inset-inline-end-md at-cursor-pointer"
      [attr.disabled]="disabled()">
      <svg
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M19.9201 8.94995L13.4001 15.47C12.6301 16.24 11.3701 16.24 10.6001 15.47L4.08008 8.94995"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round" />
      </svg>
    </div>
  </label>

  @if (isDropdownOpen) {
    @if (isLoading()) {
      <div
        class="loader-container at-flex at-align-center at-justify-center at-h-100">
        <lib-loader [size]="'sm'"></lib-loader>
      </div>
    }
    <div
      class="dropdown-list-container at-inset-inline-start-0 at-position-absolute">
      @if (!showNoSearchResult) {
        <div class="dropdown-list at-text-start at-px-md at-pt-md">
          @for (
            option of filteredOptions.length ? filteredOptions : options();
            track option.value;
            let optionIndex = $index
          ) {
            <div class="dropdown-item at-cursor-pointer at-mb-md">
              <ng-container
                [ngTemplateOutlet]="optionItemTemplate"
                [ngTemplateOutletContext]="{
                  $implicit: option,
                  i: optionIndex,
                }">
              </ng-container>
            </div>
          }
        </div>

        <div class="at-flex at-justify-end at-gap-md at-p-md">
          <button
            type="button"
            class="at-bg-transparent at-text-neutral-700 at-text-sm at-px-lg at-py-sm at-radius-sm at-border at-border-neutral-300"
            (click)="closeDropdown()">
            Cancel
          </button>

          <button
            type="button"
            [disabled]="
              !temporarySelectedOption || !temporarySelectedOption.value
            "
            class="at-bg-primary-500 at-text-white at-text-sm at-px-lg at-py-sm at-radius-sm"
            (click)="setOptionValue(temporarySelectedOption)">
            Select
          </button>
        </div>
      } @else {
        <div class="at-py-md at-px-2xl">
          {{ noSearchResultLabel() }}
        </div>
      }
    </div>
  }
</div>

<!-- option items in case of there is data -->
<ng-template #optionItemTemplate let-option let-optionIndex="i">
  @if (option) {
    @if (option[selectedKeyName()]) {
      <!-- expandable feature -->
      <div
        class="at-flex at-align-center at-gap-md at-px-lg at-py-sm at-w-100 at-border-bottom at-border-neutral-200"
        (click)="!option.children.length && selectOption(option)"
        [class.at-ps-6xl]="!option.children.length"
        [class.at-bg-neutral-50]="
          option.expand || (!option.expand && !option.children.length)
        ">
        @if (option.children?.length) {
          @if (option.expand) {
            @if (option.isTopLevel) {
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                class="at-inline-block at-flex-shrink-0 at-cursor-pointer"
                (click)="toggleChildren(option)"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M8 12H16"
                  stroke="#4B4EFC"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
                <path
                  d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                  stroke="#4B4EFC"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            } @else {
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 25"
                fill="none"
                class="at-inline-block at-flex-shrink-0 at-cursor-pointer"
                (click)="toggleChildren(option)"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M6 12.5H18"
                  stroke="#4B4EFC"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            }
          } @else {
            @if (option.isTopLevel) {
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                class="at-inline-block at-flex-shrink-0 at-cursor-pointer"
                fill="none"
                (click)="toggleChildren(option)"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M8 12H16"
                  stroke="#4B4EFC"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
                <path
                  d="M12 16V8"
                  stroke="#4B4EFC"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
                <path
                  d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                  stroke="#4B4EFC"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            } @else {
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 25"
                fill="none"
                class="at-inline-block at-flex-shrink-0 at-cursor-pointer"
                (click)="toggleChildren(option)"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M6 12.5H18"
                  stroke="#4B4EFC"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
                <path
                  d="M12 18.5V6.5"
                  stroke="#4B4EFC"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            }
          }
        } @else {
          <span
            class="checkmark at-rounded-circle at-bg-white at-flex at-align-center at-justify-center"
            [ngClass]="{
              'at-border-neutral-300': !option.isSelected,
              'at-border-primary-500 checked': option.isSelected,
            }">
            <span></span>
          </span>
        }

        {{ option[selectedKeyName()] }}
      </div>
    }
  }

  <!-- recursive option children part -->
  @if (option.children?.length && option.expand) {
    @for (
      optionChildren of option.children;
      track $index;
      let optionChildrenIndex = $index
    ) {
      <ng-container
        [ngTemplateOutlet]="optionItemTemplate"
        [ngTemplateOutletContext]="{
          $implicit: optionChildren,
          i: optionChildrenIndex,
        }" />
    }
  }
</ng-template>
